import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { ModelConnector } from 'stores';
import { Button, Checkbox, Container, Form, Input, LinkedIdeas, NewEntity, NewEntityMobile, PlatformSelection, RichText } from "components";
import IdeaViewModel from './idea.model';

import LinkedIdeasModal from 'modals/linkedIdeas/linkedIdeas';

import classNames from 'classnames/bind';
import styles from './idea.scss';
import SVG from 'components/svg/svg';

const cx = classNames.bind(styles);

const IdeaForm = observer(({ model }) => {
	if (model.isLoading) return null;

	if (!model.idea) return;

	return (
		<Container noPadding isLoading={model.isLoading}>
			<Form className={cx('create-idea-form')}>
				<Form.Item className='idea-form-item'>
					<Input value={model.idea.title} 
						onChange={(e) => model.idea.setTitle(e.target.value)} 
						placeholder="new content idea" 
						inputStyle={{ backgroundColor: 'transparent' }}
					/>
				</Form.Item>
				<Form.Row>
					<div className={cx('idea-platforms')}>
						<Form.Item label="Selected platforms:" labelPosition="row" className={classNames(cx('idea-form-item', 'show-desktop-only'))}>
							<PlatformSelection
								platforms={model.platforms}
								togglePlatform={model.idea.togglePlatform}
								workspacePlatforms={model.workplacePlatforms}
							/>
						</Form.Item>
						<Form.Item className={classNames(cx('idea-form-item', 'show-mobile-only'))}>
							<div>Selected platforms:</div>
							<PlatformSelection
								platforms={model.platforms}
								togglePlatform={model.idea.togglePlatform}
								workspacePlatforms={model.workplacePlatforms}
							/>
						</Form.Item>
						<Form.Item>
							<Checkbox label="Collaboration content" checked={model.showSponsoredPlatforms} titleIcon='handshake' onChange={(e) => model.setShowSponsoredPlatforms(e.target.checked)} />
						</Form.Item>
					</div>
					{model.shouldFetch && 
						<div className={cx('idea-linked-ideas', 'show-desktop-only')}>
							<p className="mb-1">Linked content</p>
							<LinkedIdeas
								onAddLinkedIdea={model.openLinkIdeas}
								onUnlink={model.idea.removeLinkedIdea}
								ideas={model.idea.linked_ideas}
							/>
						</div>}
				</Form.Row>
				<Form.Item className={classNames(cx('show-mobile-only'))}>
				{model.shouldFetch && 
					<div className={cx('idea-linked-ideas')}>
						<p className="mb-1">Linked content:</p>
						<LinkedIdeas
							onUnlink={model.idea.removeLinkedIdea}
							onAddLinkedIdea={model.openLinkIdeas}
							ideas={model.idea.linked_ideas}
						/>
					</div>}
				</Form.Item>
				<Form.Item label="Notes" >
					<RichText initialState={(model.idea.rich_notes || model.idea.notes) ?? ''} onChange={(state) => model.idea.setNotes(state)}/>
				</Form.Item>
				{!model.shouldFetch && 
				<Form.Item className={cx('show-desktop-only')}>
					<NewEntity
						className="mb-1"
						placeholder="additional content idea that supports this content"
						confirmText="Create additional idea"
						icon="add"
						valueKey="title"
						buttonType="greyed"
						inputStyle={{ backgroundColor: 'transparent' }}
						onSubmit={model.addLinkedIdea}
					/>
					<div className={cx('tejik-linked-ideas')}>
						{Array.from(model.idea.linked_ideas).map((idea) => (
							<div key={idea.id} className={cx('tejik-linked-idea', idea.id)}>
								{idea.title}
							</div>
						))}
					</div>
				</Form.Item>
				}
				{!model.shouldFetch && 
				<Form.Item className={cx('show-mobile-only')}>
					<NewEntityMobile
						className="mb-1"
						placeholder="enter supporting content"
						confirmText="Create"
						icon="add"
						title="Additional Content Idea"
						valueKey="title"
						buttonType="greyed"
						inputStyle={{ backgroundColor: 'transparent' }}
						onSubmit={model.addLinkedIdea}
					/>
					<div className={cx('tejik-linked-ideas')}>
						{Array.from(model.idea.linked_ideas).map((idea) => (
							<div key={idea.id} className={cx('tejik-linked-idea', idea.id)}>
								{idea.title}
							</div>
						))}
					</div>
				</Form.Item>
				}
				{model.AuthStore.hasMemberAccess && <Form.Item className={cx('align-items-end')} style={{ padding: 0 }}>
					{model.shouldFetch ?
						<Button
							onClick={model.removeIdea}
							type="secondary"
							shape="hollow"
							icon="delete"
							style={{ marginTop: '0.5em' }}
						>
							Delete Idea
						</Button>
						:
						<Button onClick={model.saveIdea}>Save Idea</Button>}
				</Form.Item>}
			</Form>
			<LinkedIdeasModal
				onOk={model.hideLinkIdeasModal}
				onCancel={model.hideLinkIdeasModal}
				visible={model.visibleLinkIdeasModal}
				idea={model.idea}
			/>
		</Container>
	);
});

const Idea = ModelConnector(IdeaForm, IdeaViewModel)

export { Idea };

const IdeaView = observer(({ model, onOk }) => (
	<Container title="Idea" actions={<>
		<Button
			type="text"
			icon={model.idea?.starred_at ? 'star' : 'star_border'}
			onClick={(e) => {
				e.stopPropagation();
				model.idea.setStarredAt();
			}}
			style={{ padding: 0, marginRight: '1em' }}
		/>
		{model.AuthStore.hasMemberAccess && <Button type="secondary" icon="view_timeline" onClick={model.moveIdeaToCampaign}>Move to Campaign</Button>}
		<Link to="/ideas"><SVG name="close" /></Link>
	</>}>
		<IdeaForm model={model} onOk={onOk} />
	</Container>

));

export default ModelConnector(IdeaView, IdeaViewModel);
