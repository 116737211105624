import React from 'react';
import request from 'api/request';
import { destroy, flow, getParent, getSnapshot, types } from 'mobx-state-tree';
import { Input, Modal } from 'components';
import debounce from 'lodash.debounce';
import { Observer } from 'mobx-react-lite';

const { confirm, message } = Modal;
const { array, boolean, enumeration, model, optional, identifier, string, number, maybeNull } = types;

const HookType = enumeration('HookType', ['TUTORIAL', 'STORY', 'RESULTS', 'CALLOUT', 'NURTURE']);

export const HookModel = model('HookModel', {
	id: identifier,
	name: string,
	type: maybeNull(HookType),
	order: number,
	hook_group_id: string,
})
	.views((self) => ({
		get baseURL() {
			return `${self.parent.baseURL}/${self.id}`;
		},
		get parent() {
			return getParent(self, 4);
		},
		get hookGroup() {
			return getParent(self, 2);
		},
	}))
	.actions((self) => ({
		saveHook: debounce(() => self.updateHook(), 700),
		deleteHook: flow(function* () {
			try {
				const confirmDelete = yield confirm({
					text: 'Are you sure you want to delete this hook?',
					children: 'This cannot be undone.',
					okText: 'Delete'
				});
				if (!confirmDelete) return;

				yield request.delete(self.baseURL);
				self.hookGroup.removeHook(self);
				message({ type: 'success', text: 'Successfully deleted hook.' });
			} catch (err) {
			} finally {
			}
		}),
		updateHook: flow(function* () {
			try {
				yield request.put(self.baseURL, getSnapshot(self));
				message({ type: 'success', text: 'Successfully updated hook.' });
			} catch (err) {
				console.error(err);
				throw err;
			} finally {
			}
		}),
		setName(name) {
			self.name = name;
			self.saveHook();
		}
	}));

export const HookGroupModel = model('HookGroupModel', {
	id: identifier,
	name: string,
	hooks: array(HookModel),
}).views((self) => ({
	get baseURL() {
		return `${self.parent.baseURL}/group/${self.id}`;
	},
	get parent() {
		return getParent(self, 2);
	},
	get orderedHooks() {
		return Array.from(self.hooks).sort((a, b) => a.order - b.order);
	},
})).actions((self) => ({
	saveHookGroup: debounce(() => self.updateHookGroup(), 700),
	deleteHookGroup: flow(function* () {
		try {
			const confirmDelete = yield confirm({
				text: 'Are you sure you want to delete this hook group?',
				children: 'This cannot be undone.',
				okText: 'Delete'
			});
			if (!confirmDelete) return;

			yield request.delete(self.baseURL);
			self.parent.removeHookGroup(self);
			message({ type: 'success', text: 'Successfully deleted hook group.' });
		} catch (err) {
		} finally {
		}
	}),
	updateHookGroup: flow(function* () {
		try {
			yield request.put(self.baseURL, getSnapshot(self));
			message({ type: 'success', text: 'Successfully updated hook group.' });
		} catch (err) {
			console.error(err);
			throw err;
		} finally {
		}
	}),
	setName(name) {
		self.name = name;
		self.saveHookGroup();
	},
	removeHook(hook) {
		destroy(hook);
	},
	onDragEnd(result) {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const orderedHooks = self.orderedHooks;

		const hooksToUpdate = [];

		if (result.source.index > result.destination.index) {
			// Coming from the bottom up
			const sourceHook = orderedHooks.at(result.source.index);
			const destinationHook = orderedHooks.at(result.destination.index);
			const temp = destinationHook.order;
			for (const hook of orderedHooks) {
				if (hook.order >= destinationHook.order && hook.order < sourceHook.order) {
					hook.order = hook.order + 1;
					hooksToUpdate.push(hook);
				}
			}
			sourceHook.order = temp;
			hooksToUpdate.push(sourceHook);
		} else {
			// Moving from the top down
			const sourceHook = orderedHooks.at(result.source.index);
			const destinationHook = orderedHooks.at(result.destination.index);
			const temp = destinationHook.order;
			for (const hook of orderedHooks) {
				if (hook.order <= destinationHook.order && hook.order > sourceHook.order) {
					hook.order = hook.order - 1;
					hooksToUpdate.push(hook);
				}
			}
			sourceHook.order = temp;
			hooksToUpdate.push(sourceHook);
		}

		for (const hook of hooksToUpdate) {
			hook.updateHook();
		}
	},
}));

// Model
const HooksModel = model('HooksModel', {
	hookGroups: array(HookGroupModel),
	newHookGroupName: optional(string, ''),
	isLoading: optional(boolean, true),
	searchTerm: optional(string, ''),
})
	.views((self) => ({
		get baseURL() {
			return `${self.props.workspace.baseURL}/settings/social/hooks`;
		},
		get hookGroupOptions() {
			return self.hookGroups.map((hookGroup) => ({
				label: hookGroup.name,
				value: hookGroup.id,
			}))
		}
	}))
	.actions((self) => ({
		afterCreate() {
			self.fetchHookGroups();
		},
		searchHookGroups: debounce(() => self.fetchHookGroups(), 700),
		fetchHookGroups: flow(function* () {
			try {
				const { data } = yield request.get(self.baseURL, {
					params: {
						search_term: self.searchTerm,
					}
				});
				self.hookGroups = data;
			} catch (err) {
				throw err;
			} finally {
				self.isLoading = false;
			}
		}),
		addHookGroup: flow(function* () {
			try {
				const result = yield confirm({
					text: 'Create new hook group',
					children: <Observer>{() =>
						<Input
							placeholder="Hook group name"
							value={self.newHookGroupName}
							onChange={(e) => self.setNewHookGroupName(e.target.value)}
						/>
					}</Observer>
				});

				if (!result) return;

				const { data } = yield request.post(`${self.baseURL}/group`, { name: self.newHookGroupName });

				self.hookGroups.push(data);
				self.setNewHookGroupName('');
				message({ type: 'success', text: 'Successfully create hook group.' });
			} catch (err) {
				console.log(err);
				throw err;
			}
		}),
		setNewHookGroupName(newHookGroupName) {
			self.newHookGroupName = newHookGroupName;
		},
		addHook: flow(function* (e, { name, hook_group_id }) {
			try {
				const hookGroup = self.hookGroups.find((hookGroup) => hookGroup.id === hook_group_id);
				const maxOrder = Math.max(...hookGroup.hooks.map(({ order }) => order), 0);
				const hook = { name, hook_group_id, order: maxOrder + 1 };
	
				const { data } = yield request.post(self.baseURL, hook);
				hookGroup.hooks.push(data);
				message({ type: 'success', text: 'Successfully added hook.' });
			} catch (err) {
				throw err;
			}
		}),
		removeHookGroup(hookGroup) {
			destroy(hookGroup);
		},
		setSearchTerm(searchTerm) {
			self.searchTerm = searchTerm;
			self.isLoading = true;
			self.searchHookGroups();
		},
	}));

export default HooksModel;
