import request from 'api/request';
import { flow, getSnapshot, types } from 'mobx-state-tree';
import { PlatformOrderList } from 'utils/utils';

import { Modal } from 'components';
import { IdeaModel } from 'models/idea.model';
import { reaction } from 'mobx';

const { confirm, message } = Modal;
const { model, boolean, optional, maybeNull } = types;
// Model
const WorkspaceViewModel = model('WorkspaceViewModel', {
  isLoading: optional(boolean, true),
  idea: maybeNull(IdeaModel),
  visibleLinkIdeasModal: optional(boolean, false),

  showSponsoredPlatforms: optional(boolean, false)
})
  .views(self => ({
    get baseURL() {
      return self.shouldFetch
        ? `${self.WorkspaceStore.currentBaseURL}/ideas/${self.ideaId}`
        : `${self.WorkspaceStore.currentBaseURL}/ideas`;
    },
    get campaignBaseURL() {
      return `${self.WorkspaceStore.current_workspace.baseURL}/campaigns`;
    },
    get shouldFetch() {
      if (self.props.onOk) return false;

      return self.ideaId;
    },
    get ideaId() {
      return self.idea?.id === 'new' ? false : self.props.params.idea_id;
    },
    get platforms() {
      if(!self.idea) {
        return [];
      }

      const platformsSorted = self.idea?.platforms.slice().sort((a, b) => {
        const indexA = PlatformOrderList.findIndex(type => a === type);
        const indexB = PlatformOrderList.findIndex(type => b === type);
        return indexA - indexB;
      });

      if (self.WorkspaceStore.current_workspace.primary_platform) {
        const index = platformsSorted.findIndex(p => p === self.WorkspaceStore.current_workspace.primary_platform);
        platformsSorted.unshift(platformsSorted.splice(index, 1)[0]);
      }
          
      return platformsSorted;
    },
    get workplacePlatforms() {
      const platformsSorted = self.WorkspaceStore.current_workspace.platforms.slice().sort((a, b) => {
        const indexA = PlatformOrderList.findIndex(type => a === type);
        const indexB = PlatformOrderList.findIndex(type => b === type);
        return indexA - indexB;
      });

      if (self.WorkspaceStore.current_workspace.primary_platform) {
        const index = platformsSorted.findIndex(p => p === self.WorkspaceStore.current_workspace.primary_platform);
        platformsSorted.unshift(platformsSorted.splice(index, 1)[0]);
      }
          
      return platformsSorted;
    },
  }))
  .volatile(self => ({
    disposer: null
  }))
  .actions(self => ({
    afterCreate() {
      if (self.shouldFetch) {
        self.fetchIdea();

        self.disposer = reaction(
          () => self.ideaId, // Specify the observable to track
          () => self.fetchIdea()
        );
      } else {
        self.idea = {
          id: 'new',
          title: '',
          platforms: [...self.WorkspaceStore.current_workspace.platforms]
        };
        self.isLoading = false;
      }
    },
    beforeDestroy() {
      if (self.disposer) {
        self.disposer();
      }
    },
    fetchIdea: flow(function* () {
      try {
        self.isLoading = true;
        self.visibleLinkIdeasModal = false;
        const { data } = yield request.get(self.baseURL);
        self.idea = data;

        if (data.campaign?.id) {
          self.props.navigate(`/campaigns/${data.campaign?.id}`, {
            replace: true
          });
        }

        self.showSponsoredPlatforms = data.sponsored_platforms?.length > 0;

        self.isLoading = false;
      } catch (err) {
        console.error(err);
      }
    }),
    moveIdeaToCampaign: flow(function* () {
      try {
        const userConfirm = yield confirm({
          text: 'Are you sure you want to create a campaign from this idea?',
          children: 'You cannot undo this action.',
          okText: 'Create'
        });
        if (!userConfirm) return;

        const { data } = yield request.post(self.campaignBaseURL, {
          idea_id: self.idea.id
        });

        self.props.navigate(`/campaigns/${data.campaign_id}`);
      } catch (err) {
        console.error(err);
      }
    }),
    createIdea: flow(function* () {
      const { data } = yield request.post(self.baseURL, getSnapshot(self.idea));
      message({ type: 'success', text: 'Successfully created idea.' });
      return data;
    }),
    removeIdea: flow(function* () {
      if (
        yield confirm({
          text: 'Are you sure you want to delete this idea?',
          okText: 'Delete'
        })
      ) {
        yield request.delete(self.baseURL);
        self.props.navigate('/ideas', { replace: true });
      }
    }),
    saveIdea: flow(function* () {
      let data;

      if (!self.ideaId) {
        data = yield self.createIdea();
      }

      if (self.props.onOk) {
        self.props.onOk(data);
        self.idea = null;
      } else {
        self.props.navigate('/ideas');
      }
    }),
    updateIdea: flow(function* () {
      yield request.put(self.baseURL, getSnapshot(self.idea));
      message({ type: 'success', text: 'Successfully updated idea.' });
    }),

    setShowSponsoredPlatforms(showSponsoredPlatforms) {
      if (!showSponsoredPlatforms) self.idea.sponsored_platforms = [];
      self.showSponsoredPlatforms = showSponsoredPlatforms;
    },

    // Link Ideas
    addLinkedIdea(e, { title }) {
      if (!title) {
        message({
          type: 'error',
          text: 'You must provide a title for the supporting idea.'
        });
        return;
      }

      self.idea.addNewLinkedIdea({
        id: String(Date.now()),
        title
      });
    },
    openLinkIdeas() {
      self.showLinkIdeasModal();
    },
    showLinkIdeasModal() {
      self.visibleLinkIdeasModal = true;
    },
    hideLinkIdeasModal() {
      self.visibleLinkIdeasModal = false;
    }
  }));

export default WorkspaceViewModel;
