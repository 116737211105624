import { useState } from "react";
import { Button, Container, Input, Popover, Table, Title, NewEntity, UserImage, Form, PlatformSelection, Info } from "components";
import SVG from "components/svg/svg";
import { observer, Observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { ModelConnector } from "stores";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CampaignStatusesModel from "./campaign-statuses.model";
import classNames from 'classnames/bind';
import styles from './campaign-statuses.scss';
import { ColorOptions, PlatformIconMap, PlatformLabelMap } from "utils/utils";

const cx = classNames.bind(styles);

const ColorPicker = observer(({ color: c, setColor }) => {
	return <>
		<span className={cx('color-picker-label')}>pick a color</span>
		<div className={cx('color-picker')}>
			{ColorOptions.map((color) => (
				<div key={color} className={cx('color-preview', { selected: c === color })} style={{ backgroundColor: color }} onClick={(e) => setColor(color, e)} />
			))}
		</div>
	</>
});

const AssigneeSelection = ({ assignee, onSelect, users, isApprover }) => (
	<div className={cx('assignee-selection')}>
		{users.map((user) => (
			<div key={user.id} className={cx('assignee', { 'active': assignee === user.id })} onClick={() => onSelect(user.id)}>
				{!isApprover ? 
				<>
					<UserImage user={user} />
					<p>{user.first_name} {user.last_name}</p>
					{assignee === user.id ? <SVG name="check_box" size={20} /> : <SVG name="check_box_outline_blank" size={20}/>}
				</> : 
				<>
					<p style={{ width: 200 }}>Approval by</p>
					<UserImage user={user} style={{ marginLeft: '30%'}}/>
					{assignee === user.id ? <SVG name="check_box" size={20} /> : <SVG name="check_box_outline_blank" size={20}/>}
				</>}
			</div>
		))}
	</div>
)

const Status = ({ model, status, index }) => {
	return (<Draggable draggableId={status.id} index={index}>
		{(provided) => (
			<div
				className={cx('draggable-item', `draggable-item-${status.type.toLowerCase()}`)}
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
			>
				<Observer>{() => (<>
					<SVG className={cx('text-neutral-2')} name="drag_indicator" />
					<div style={{ backgroundColor: status.color, borderRadius: 20 }} className={cx('status-color-preview')} />
					<div style={{ fontSize: 14 }}>{status.name}</div>
					<UserImage user={status.assignee} style={{ marginRight: 50 }}/>
					{status.type !== 'CLOSED' && <Popover position="right"
						content={<NewStatusForm key={model.activeStatuses.length} onSubmit={status.updateFields} users={model.WorkspaceStore.current_workspace.users} status={status} owners={model.WorkspaceStore.current_workspace.owners} />}
					>
						<Button className={cx({ 'not-visible': status.type === 'CLOSED' })} type="text" icon="edit" />
					</Popover>}
					<Button className={cx({ 'not-visible': status.type === 'CLOSED' })} type="text" icon="delete" onClick={status.deleteStatus} />
				</>)}</Observer>

			</div>
		)}
	</Draggable>);
}

const NewStatusForm = observer(({ onSubmit, users, status, owners }) => {
	const [color, setColor] = useState(status ? status.color : '');
	const [name, setName] = useState(status ? status.name : '');
	const [needsApproval, setNeedsApproval] = useState(status ? status.needsApproval : false);
	const [assignee, setAssignee] = useState(status ? status.assignee?.id : '');
	const [approver, setApprover] = useState(status ? status.approver?.id : '');

	const resetState = () => {
		setColor('');
		setName('');
		setNeedsApproval(false);
		setAssignee('');
		setApprover('');
	};

	const toggleAssignee = (userId) => {
		if (assignee === userId) setAssignee('');
		else setAssignee(userId);
	}

	const toggleApprover = (userId) => {
		if (approver === userId) setApprover('');
		else setApprover(userId);
	}

	return <Form onSubmit={(e) => {
		onSubmit(e, { assignee_id: assignee, color, name, needs_approval: needsApproval, approver_id: approver });
		if(!status) resetState();
	}}>
		<Title size={6} className="flex-start">
			<SVG name="add" width="30" style={{ marginRight: 10 }} />
			Add new status
		</Title>
		<Form.Item>
			<Input value={name} onChange={(e) => setName(e.target.value.toUpperCase())} size="medium" placeholder="enter task name/description" />
		</Form.Item>
		<Form.Item>
			<ColorPicker color={color} setColor={setColor} />
		</Form.Item>
		<Form.Item>
			<span style={{ marginBottom: 12 }} className={cx('color-picker-label')}>assigned to</span>
			<AssigneeSelection users={users} assignee={assignee} onSelect={toggleAssignee} />
		</Form.Item>
		{owners && 
			<Form.Item>
				<span style={{ marginBottom: 12 }} className={cx('color-picker-label')}>status needs approval</span>
				<AssigneeSelection users={owners} assignee={approver} onSelect={toggleApprover} isApprover={true}/>
			</Form.Item>
		}
		<Button htmlType="submit" style={{ marginTop: 12 }}>{status ? 'Save status' : 'Save new status'}</Button>
	</Form>
});


const CampaignStatuses = observer(({ model }) => {
	const platforms = model.WorkspaceStore.current_workspace.joinedSocialPlatforms;

	const columns = [{
		title: 'Status Templates',
		with: 'fit',
		render: (row) => (
			<Input
				value={row.name}
				onChange={(e) => row.setName(e.target.value)}
				size="small"
				className={classNames('mr-1', {'status-name-input': row.id === model?.selectedStatusTemplate?.id})}
				inputStyle={{ backgroundColor: 'transparent'}}
				hidden
			/>
		)
	},
	{
		render: (row) => (
			<Popover
				content={
					<PlatformSelection
						platforms={row.filteredPlatforms}
						togglePlatform={row.togglePlatform}
						workspacePlatforms={platforms}
					/>
				}
				position="left"
			>
				<div className={cx('status-template-platforms')}>
					{row.filteredPlatforms?.length > 0 ?
						row.filteredPlatforms.map((platform) =>  {
							return <SVG key={platform} name={PlatformIconMap.get(platform)} size={20} />
						})
						:
						<span className={cx('text-neutral-2')}>No platforms selected</span>
					}
				</div>
			</Popover>
		)
	}, {
		render: (row) => (<div className={cx('status-template-actions')}>
			<Button type="text" icon="edit" onClick={() => model.setSelectedStatusTemplate(row.id)} />
			<Button type="text" icon="delete" onClick={() => model.deleteStatusTemplate(row.id)} />
		</div>
		),
	}];

	return (
		<Container title="Campaign Statuses" titleSize={1} titleIcon={'dataset'} titleWeight={400} actions={<Link to="/workspace-settings"><SVG name="close" /></Link>}>
			<Container.Column span={6}>
				<Table
					className={cx('campaign-statuses-table', 'mb-1')}
					columns={columns}
					data={model.templates}
					selectedId={model.selectedStatusTemplate?.id}
				/>
				<div className="d-flex">
					<Popover
						position="left"
						contentClass={cx('new-status-template-popup')}
						content={
							<NewEntity
								placeholder="Enter status template name"
								icon="add"
								valueKey="name"
								options={[
									{
										key: 'platforms',
										type: 'multi-select',
										options: model.WorkspaceStore.current_workspace.joinedSocialPlatforms.map((platform) => ({
											icon: PlatformIconMap.get(platform),
											label: PlatformLabelMap.get(platform),
											value: platform,
											disabled: model.selectedPlatforms.includes(platform)
										})),
										placeholder: 'select platform(s)',
										className: cx('new-status-template-select'),
										menuClass: cx('new-status-template-menu')
									},
								]}
								onSubmit={model.addStatusTemplate}
								title={'New Status Template'}
								inputClass={cx('new-status-template-input')}
							/>
						}
					>
						<Button type="hollow" icon="add">New status template</Button>
					</Popover>
				</div>
			</Container.Column>
			{model.selectedStatusTemplate &&
				<Container.Column span={6}>
					<DragDropContext onDragEnd={model.onDragEnd}>
						<div className={cx('draggable-list', 'statuses-list')}>
							<div className={cx('statuses-header')}>Active Statuses</div>
							<Droppable droppableId="ACTIVE">
								{(provided) => (
									<div ref={provided.innerRef} {...provided.droppableProps}>
										<Observer>{() => (<>
											{model.activeStatuses.length === 0 && <div className={cx('empty-list-placeholder')}>Move status here to consider Active</div>}
											{model.activeStatuses.map((status, index) => <Status model={model} status={status} index={index} key={status.id} />)}
											{provided.placeholder}
										</>)}</Observer>
									</div>
								)}
							</Droppable>
							<div className="d-flex">
								<Popover
									position="left"
									content={<NewStatusForm key={model.activeStatuses.length} onSubmit={model.addStatus} users={model.WorkspaceStore.current_workspace.users} owners={model.WorkspaceStore.current_workspace.owners} />}
								>
									<Button type="hollow" icon="add" className={cx('mt-1')}>Add status</Button>
								</Popover>
							</div>
						</div>
						<div className={cx('draggable-list', 'statuses-list')}>
							<div className={cx('statuses-header')}>Done Statuses</div>
							<Droppable droppableId="DONE">
								{(provided) => (
									<div ref={provided.innerRef} {...provided.droppableProps}>
										<Observer>{() => (<>
											{model.doneStatuses.length === 0 && <div className={cx('empty-list-placeholder')}>Move status here to consider Done</div>}
											{model.doneStatuses.map((status, index) => <Status model={model} status={status} index={index} key={status.id} />)}
											{provided.placeholder}
										</>)}</Observer>
									</div>
								)}
							</Droppable>
						</div>
						<div className={cx('draggable-list', 'statuses-list')}>
							<div className={cx('statuses-header')}>Closed Statuses</div>
							<Droppable droppableId="CLOSED">
								{(provided) => (
									<div ref={provided.innerRef} {...provided.droppableProps}>
										<Observer>{() => (<>
											{model.closedStatuses.length === 0 && <div className={cx('empty-list-placeholder')}>Move status here to consider Closed</div>}
											{model.closedStatuses.map((status, index) => <Status model={model} status={status} index={index} key={status.id} />)}
											{provided.placeholder}
										</>)}</Observer>
									</div>
								)}
							</Droppable>
						</div>
					</DragDropContext>
				</Container.Column>
			}
			<Info infoKey="status-templates" />
		</Container>
	);
});

export default ModelConnector(CampaignStatuses, CampaignStatusesModel);;
