import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { rootStore } from 'stores';
import { ContentLinkIconMap, closePopover } from 'utils/utils';
import { Form, Input, Popover, Title } from 'components';
import SVG from 'components/svg/svg';

import classNames from 'classnames/bind';
import styles from './contentLinks.scss';
const cx = classNames.bind(styles);

const EditContentLink = observer(({ content_link_id, campaign_content_link, onSubmit }) => {
	const [url, setUrl] = useState('');

	return (
		<Form onSubmit={
			campaign_content_link ?
				(e) => {
					closePopover(e);
					campaign_content_link.updateCampaignContentLink();
				} :
				(e) => {
					closePopover(e);
					onSubmit({ content_link_id, url })
				}
		}>
			<Title size={6}>Link:</Title>
			<Form.Item>
				<Input
					placeholder="url for content link"
					value={campaign_content_link?.url || url}
					onChange={campaign_content_link ? (e) => campaign_content_link.setUrl(e.target.value) : (e) => setUrl(e.target.value)}
					size="small"
				/>
				<span className={cx('helper-text')}>press ENTER to {campaign_content_link ? 'update' : 'add'} link</span>
			</Form.Item>
		</Form>
	)
});

const ContentLinks = observer(({ single_column = false, campaign_content_links, platform, addCampaignContentLink }) => {
	const content_links = rootStore.WorkspaceStore.current_workspace.content_links
		.filter((content_link) => content_link.platform === platform);

	return (
		<div className={cx('campaign-content-container', { 'single-column': single_column })}>
			{content_links.map((content_link) => {
				const foundContentLink = campaign_content_links.find((link) => link.content_link_id === content_link.id);
				return (
					<div
						key={content_link.id}
						className={cx('campaign-content-link')}
					>
						<SVG name={ContentLinkIconMap.get(content_link.type)} size={24} />
						<div className={cx('campaign-content-link-content')}>
							<div className={cx('campaign-content-link-name')}>{content_link.name}</div>
							{foundContentLink?.url &&
								<a
									className={cx('campaign-content-link-url')}
									href={foundContentLink?.url}
									target="_blank"
								>
									{foundContentLink.url}
								</a>
							}
						</div>
						<Popover
							position="left"
							width="300px"
							content={
								<EditContentLink
									content_link_id={content_link.id}
									campaign_content_link={foundContentLink}
									onSubmit={addCampaignContentLink}
								/>
							}
						>
							<SVG name={foundContentLink ? 'link' : 'add'} />
						</Popover>
					</div>
				)
			})}
		</div>);
});

export default ContentLinks;