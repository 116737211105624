import { Container, Info, Input, Menu, Select, WeekdaySelector } from "components";
import SVG from "components/svg/svg";
import { Link } from "react-router-dom";

import classNames from 'classnames/bind';
import styles from './scheduling-targets.scss';
import { IsSocialMediaPlatform, PlatformIconMap, PlatformLabelMap } from "utils/utils";
import { Fragment } from "react";
import SchedulingTargetsModel from "./scheduling-targets.model";
import { observer } from "mobx-react-lite";
import { ModelConnector } from "stores";

const cx = classNames.bind(styles);

const SharingConsistencyOptions = [{ label: 'Day', value: 'DAILY' },{ label: 'Week', value: 'WEEKLY' },{ label: 'Month', value: 'MONTHLY' }];
const SharingConsistencyOptionsPlural = [{ label: 'Days', value: 'DAILY' },{ label: 'Weeks', value: 'WEEKLY' },{ label: 'Months', value: 'MONTHLY' }];

let TimeSelectOptions = [];
[12,1,2,3,4,5,6,7,8,9,10,11].forEach((val) => { TimeSelectOptions = [...TimeSelectOptions, { label: (val < 10 ? '0'+val : val) + ':00 am', value: val+':00' },  { label: (val < 10 ? '0'+val : val)  + ':15 am', value: val+':15' },  { label: (val < 10 ? '0'+val : val)  + ':30 am', value: val+':30' },  { label: (val < 10 ? '0'+val : val)  + ':45 am', value: val+':45' }]});
[12,1,2,3,4,5,6,7,8,9,10,11].forEach((val) => { TimeSelectOptions = [...TimeSelectOptions, { label: (val < 10 ? '0'+val : val) + ':00 pm', value: (12+val)+':00' },  { label: (val < 10 ? '0'+val : val)  + ':15 pm', value: (12+val)+':15' },  { label: (val < 10 ? '0'+val : val)  + ':30 pm', value: (12+val)+':30' },  { label: (val < 10 ? '0'+val : val)  + ':45 pm', value: (12+val)+':45' }]});

const CoreSchedulePicker = observer(({ schedule }) => (
	<div className={cx('tejik-schedule-picker')}>
		<div className={cx('tejik-item-row')}>
			<div className={classNames('tejik-preffix', 'primary-share-label')}>share every</div>
			<Input 
				type={'number'} 
				onChange={(e) => schedule.setPillarSchedule({ sharing_frequency: parseInt(e.target.value) })}
				defaultValue={schedule?.pillar?.sharing_frequency} 
				className={cx('primary-platform-number')}
			/>
			<Select
				options={schedule?.pillar?.sharing_frequency > 1 ? SharingConsistencyOptionsPlural : SharingConsistencyOptions}
				onChange={(value) => schedule.setPillarSchedule({ sharing_consistency: value })}
				value={schedule?.pillar?.sharing_consistency}
				placeholder="Select"
				position="left"
				type={'checked-select'}
				className={cx('primary-platform-consistency')}
				menuClass={cx('primary-platform-consistency-menu')}
			/>
		</div>
		<div className={cx('tejik-item-row')}>
			<div className={classNames('tejik-preffix','primary-share-label')}>on</div>
			<WeekdaySelector
				selectedDays={schedule?.pillar?.days_of_week}
				className={cx('primary-weekday-selector')}
				onChange={(value) => schedule.setPillarSchedule({ days_of_week: value })} 
			/>
		</div>
		<div className={cx('tejik-item-row')}>
			<div className={classNames('tejik-preffix','primary-share-label')}>at</div>
			<Select
				options={TimeSelectOptions}
				onChange={(value) => schedule.setPillarSchedule({ time_of_day: value })}
				value={schedule?.pillar?.time_of_day}
				placeholder="Time of day"
				position="left"
				type={'checked-select'}
				className={cx('primary-platform-time')}
				menuClass={cx('primary-platform-time-menu')}
			/>
		</div>
	</div>
));

const ScheduleOnOptions = [
	{ label: 'Immediately', icon: 'today', value: 0 },
	{ label: '1', value: 1 }, { label: '2', value: 2 }, { label: '3', value: 3 }, { label: '4', value: 4 }, { label: '5', value: 5 },{ label: '6', value: 6 },{ label: '7', value: 7 },
	{ label: '8', value: 8 }, { label: '9', value: 9 }, { label: '10', value: 10 }, { label: '11', value: 11 }, { label: '12', value: 12 },{ label: '13', value: 13 },{ label: '14', value: 14 },
];

const SupportingSchedulePicker = observer(({ schedule }) => (
	<div className={cx('tejik-schedule-picker')}>
		<div className={cx('tejik-item-row-secondary')}>
			<div style={{ width: 'fit-content', marginRight: '1em'}} className={classNames('tejik-preffix','primary-share-label')}>share</div>
			<Select
				options={ScheduleOnOptions}
				onChange={(value) => schedule.setSupportingSchedule({ immediate: value === 0, number_of_days_after: value > 0 ? value : null })}
				value={schedule?.supporting?.immediate ? 0 : schedule?.supporting?.number_of_days_after}
				placeholder="Schedule on"
				position="left"
				style={{ marginRight: schedule?.supporting?.showNumberOfDays ? '1em' : 0 }}
				type={'checked-select'}
				className={cx(schedule?.supporting?.showNumberOfDays ? 'secondary-platform-frequency-small' : 'secondary-platform-frequency')}
				menuClass={cx('secondary-platform-frequency-menu')}
			/>
		</div>
		{schedule?.supporting?.showNumberOfDays && 
			<div className={classNames('tejik-core-schedule')}>
				<div className={classNames('tejik-preffix','secondary-label-only')}>{schedule?.supporting?.number_of_days_after > 1 ? 'days after pillar content at' : 'day after pillar content at'}</div>
				<Select
					options={TimeSelectOptions}
					onChange={(value) => schedule.setSupportingSchedule({ time_of_day: value })}
					value={schedule?.supporting?.time_of_day}
					placeholder="Time of day"
					position="left"
					type={'checked-select'}
					className={cx('secondary-platform-time')}
					menuClass={cx('secondary-platform-time-menu')}
				/>
			</div>
		}
	</div>
));

const SocialSchedulePicker = observer(({ schedule }) => (
	<div className={cx('tejik-schedule-picker')}>
		<div className={cx('tejik-item-row-social')}>
			<div className={classNames('tejik-preffix','primary-share-label')}>share</div>
			<Input
				type={'number'}
				onChange={(e) => schedule.setSocialSchedule({ number_of_posts: Number(e.target.value) })}
				value={schedule?.social?.number_of_posts || ''}
				className={cx('primary-platform-number')}
			/>
		</div>
		<div className={cx('tejik-item-row-social')}>
			<div className={classNames('tejik-preffix','primary-share-label')}>{schedule?.social?.number_of_posts > 1 ? 'posts spaced' : 'post spaced'}</div>
			<Input
				type={'number'}
				onChange={(e) => schedule.setSocialSchedule({ spaced_number_of_days: Number(e.target.value) })}
				value={schedule?.social?.spaced_number_of_days || ''}
				className={cx('primary-platform-number')}
			/>
		</div>
		<div className={cx('tejik-item-row')}>
			<div className={classNames('tejik-preffix','primary-share-label')}>{schedule?.social?.spaced_number_of_days > 1 ? 'days apart at' : 'day apart at'}</div>
			<Select
				options={TimeSelectOptions}
				onChange={(value) => schedule.setSocialSchedule({ time_of_day: value })}
				value={schedule?.social?.time_of_day}
				placeholder="Time of day"
				type={'checked-select'}
				className={cx('social-platform-time')}
				menuClass={cx('social-platform-time-menu')}
			/>
		</div>
	</div>
));

const SchedulingTargets = observer(({ model }) => {
	if (model.isLoading) return null;

	return (
		<Container title="Scheduling Targets" titleSize={1} titleIcon={'calendar_month'} titleWeight={400} actions={<Link to="/workspace-settings"><SVG name="close" size={16} /></Link>}isLoading={model.isLoading}>
			<Container.Column span={12} className="container-description">
				<p>Create a schedule that will automatically set campaign due dates. These can be adjusted inside each campaign.</p>
			</Container.Column>
			<Container.Column span={12}>Pillar Content Platform:</Container.Column>
			<Container.Column span={2} className="d-flex">
				<Menu.Item 
					icon={PlatformIconMap.get(model.pillarSchedulingTarget.platform)}
					text={PlatformLabelMap.get(model.pillarSchedulingTarget.platform)}
					iconClass={cx('primary-platform-icon')}
					textClass={cx('primary-platform-name')}
				/>
			</Container.Column>
			<Container.Column span={10} className="d-flex">
				<CoreSchedulePicker schedule={model.pillarSchedulingTarget.schedule} />
			</Container.Column>
			<Container.Column span={12}>Other Platforms:</Container.Column>
			{model.schedulingTargets.map((schedulingTarget) => {
				const platform = schedulingTarget.platform;
				if (platform === model.pillarSchedulingTarget.platform) return null;
				return (
					<Fragment key={platform}>
						<Container.Column span={2} className="d-flex">
							<Menu.Item
								icon={PlatformIconMap.get(platform)}
								text={PlatformLabelMap.get(platform)}
								iconClass={cx('primary-platform-icon')}
								textClass={cx('primary-platform-name')}
							/>
						</Container.Column>
						{IsSocialMediaPlatform(platform) || platform === 'PINTEREST' ?
							<Container.Column span={8} className="d-flex">
								<SocialSchedulePicker schedule={schedulingTarget.schedule} />
							</Container.Column>
							:
							<Container.Column span={7} className="d-flex">
								<SupportingSchedulePicker schedule={schedulingTarget.schedule} />
							</Container.Column>
						}
						{IsSocialMediaPlatform(platform) || platform === 'PINTEREST' ?
							<Container.Column span={2} className={cx('show-desktop-only')}></Container.Column>
							:
							<Container.Column span={3} className={cx('show-desktop-only')}></Container.Column>
						}
					</Fragment>
				)
			})}
			<Info infoKey="scheduling-targets"/>
		</Container>
	);
});

export default ModelConnector(SchedulingTargets, SchedulingTargetsModel);;
