import { observer } from 'mobx-react-lite';
import { Menu, Popover } from 'components';

import classNames from 'classnames/bind';
import styles from './sidebar.scss';
import { rootStore } from 'stores';
import { useEffect } from 'react';

import { Link } from 'react-router-dom';
import { TejikLogo } from 'components/svg/svg';

const cx = classNames.bind(styles);

const WorkspaceSelect = observer(() => (
	<div className={cx('workplace-select')}>
		<Popover content={<Menu items={rootStore.WorkspaceStore.workspaceItems} />} position="top">
			<Menu.Item
				text={rootStore.WorkspaceStore?.current_workspace?.name}
				state="expand_more"
				ellipsis
			/>
		</Popover>

	</div>
));

const Sidebar = observer(({ allowWorkspaceSelect = false, bottomItems, menuItems }) => {
	useEffect(() => {
		const documentHandler = (e) => {
			const main = document.querySelector('#main-content');
			if (main.contains(e.target)) {
				rootStore.AuthStore.closeSidebar();
			}
		};

		document.addEventListener('click', documentHandler);

		return () => {
			document.removeEventListener('click', documentHandler);
		}
	}, []);

	return (
		<div className={cx('sidebar', { 'show-sidebar': rootStore.AuthStore?.showSidebar })}>
			<div className={cx('logo-container')}>
				<div className={cx('logo')}>
					<Link to="/">
						<TejikLogo />
					</Link>
				</div>
			</div>
			<Menu items={menuItems} sidebar={true} />
			<div className={cx('sidebar-spacer')} />
			<Menu items={bottomItems} />
			{allowWorkspaceSelect && <WorkspaceSelect />}
		</div>
	);
});

export default Sidebar;
