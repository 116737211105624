import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';


import AutoLinkPlugin from "./plugins/AutoLinkPlugin";

function Placeholder() {
	return <div className="tejik-editor-placeholder">Enter some text...</div>;
}

const theme = {
	placeholder: "tejik-editor-placeholder",
	paragraph: "tejik-editor-paragraph",
	list: {
		nested: {
			listitem: "tejik-editor-nested-listitem"
		},
		ol: "tejik-editor-list-ol",
		ul: "tejik-editor-list-ul",
		listitem: "tejik-editor-listitem"
	},
	link: "tejik-editor-link",
	text: {
		bold: "tejik-editor-text-bold",
		italic: "tejik-editor-text-italic",
		overflowed: "tejik-editor-text-overflowed",
		hashtag: "tejik-editor-text-hashtag",
		underline: "tejik-editor-text-underline",
		strikethrough: "tejik-editor-text-strikethrough",
		underlineStrikethrough: "tejik-editor-text-underlineStrikethrough",
	},
};

const editorConfig = {
	theme,
	// Handling of errors during update
	onError(error) {
		throw error;
	},
	// Any custom nodes go here
	nodes: [
		HeadingNode,
		ListNode,
		ListItemNode,
		QuoteNode,
		CodeNode,
		CodeHighlightNode,
		TableNode,
		TableCellNode,
		TableRowNode,
		AutoLinkNode,
		LinkNode
	]
};

// function OnChangePlugin({ onChange }) {
// 	const [editor] = useLexicalComposerContext();
// 	useEffect(() => {
// 		return editor.registerUpdateListener(({ editorState }) => {
// 			onChange(editorState);
// 		});
// 	}, [editor, onChange]);
// }

export default function RichText({ initialState, onChange }) {
	const parseInitialState = () => {
		if (!initialState) return null;

		try {
			JSON.parse(initialState);
		} catch (e) {
			const BASE = `{"root":{"children":[
				${initialState.split('\n').map((value) => (
					`{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"${value}","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}`
				))}
			],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`;
			return BASE;
		}
		return initialState;
	}
	
	function onEditorStateChange(editorState) {
		const editorStateJSON = editorState.toJSON();
		if (onChange) {
			onChange(JSON.stringify(editorStateJSON));
		}
	}

	return (
		<LexicalComposer initialConfig={{...editorConfig, editorState: parseInitialState() }}>
			<div className="tejik-editor-container">
				<div className="toolbar-container">
					<ToolbarPlugin />
				</div>
				<div className="tejik-editor-inner">
					<RichTextPlugin
						contentEditable={<ContentEditable className="tejik-editor-input" />}
						placeholder={<Placeholder />}
						ErrorBoundary={LexicalErrorBoundary}
					/>
					<HistoryPlugin />
					<ListPlugin />
					<LinkPlugin />
					<AutoLinkPlugin />
					<OnChangePlugin onChange={onEditorStateChange} ignoreSelectionChange />
				</div>
			</div>
		</LexicalComposer>
	);
}
