import { useState } from "react";
import { observer } from "mobx-react-lite";
import { rootStore } from "stores";
import { Button, Container, Form, Input, List, Modal, PlatformSelection, Select } from "components";
import { CategoryCard } from "views/app/workspace-settings/social/categories/categories.view";
import { PlatformIconMap } from "utils/utils";

import SVG from "components/svg/svg";
import { GenerateCaptionText } from "models/campaign.model";

import classNames from 'classnames/bind';
import styles from './social.scss';
import ContentLinks from "../../contentLinks";
const cx = classNames.bind(styles);

const { confirm, message } = Modal;

const SocialPostModal = observer(({ campaign_social, onOk, onCancel, visible }) => {
	const current_workspace = rootStore.WorkspaceStore.current_workspace;

	const [title, setTitle] = useState(campaign_social.selected_social_post?.title ?? '');
	const [categoryId, setCategory] = useState(campaign_social.selected_social_post?.category?.id ?? '');
	const [platforms, setPlatforms] = useState(campaign_social.selected_social_post?.platforms ?? []);

	const togglePlatform = (platform) => {
		const temp = [...platforms];
		if (platforms.includes(platform)) {
			const index = platforms.findIndex((p) => p === platform);
			temp.splice(index, 1);
			setPlatforms(temp);
		} else {
			temp.push(platform);
			setPlatforms(temp);
		}
	}

	const date = campaign_social.selected_social_post?.due_date ? new Date(campaign_social.selected_social_post?.due_date) : new Date();
	const year = date.getFullYear().toString().padStart(4, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');

	const formattedDate = `${year}-${month}-${day}`;
	const [dueDate, setDueDate] = useState(formattedDate);

	const [contentType, setContentType] = useState(campaign_social.selected_social_post?.content_type?.id ?? '');
	const [hookGroup, setHookGroup] = useState(campaign_social.selected_social_post?.hook?.hook_group_id ?? '');
	const [hook, setHook] = useState(campaign_social.selected_social_post?.hook?.id ?? '');
	const [CTA, setCTA] = useState(campaign_social.selected_social_post?.call_to_action?.id ?? '');

	const [design, setDesign] = useState(campaign_social.selected_social_post?.data?.design ?? '');
	const [customHook, setCustomHook] = useState(campaign_social.selected_social_post?.data?.custom_hook ?? '');
	const [steps, setSteps] = useState(campaign_social.selected_social_post?.data?.steps ?? '');

	const contentTypeOptions = current_workspace.content_types.map((content_type) => ({
		label: content_type.name,
		value: content_type.id,
	}));

	const hookTypeOptions = new Map();
	for (const hook of current_workspace.hook_groups) {
		hookTypeOptions.set(hook.id,
			{
				label: hook.name,
				value: hook.id,
			}
		);
	}

	const foundHookGroup = current_workspace.hook_groups.find((hg) => hg.id === hookGroup);
	const filteredHookOptions = (foundHookGroup?.hooks || []).map((hook) => ({
		label: hook.name,
		value: hook.id,
	}));

	const CTAOptions = current_workspace.call_to_actions.map((cta) => ({
		label: cta.name,
		value: cta.id,
	}));

	return (
		<Modal
			actions={<PlatformSelection
				platforms={platforms}
				togglePlatform={togglePlatform}
				workspacePlatforms={campaign_social.platforms}
			/>}
			className={cx('new-social-post-modal')}
			width="80%"
			onOk={() => {
				const [year, month, day] = dueDate.split('-').map(Number);
				const formattedDate = new Date(year, month - 1, day);

				const data = {
					...campaign_social.selected_social_post?.data,
					custom_hook: customHook,
					design,
					steps,
				};

				const socialHook = filteredHookOptions.find(({ value }) => value === hook)?.label;
				const socialCTA = CTAOptions.find(({ value }) => value === CTA)?.label;
				for (const platform of platforms) {
					const platformHashtagString = campaign_social.selected_social_post?.data[platform]?.hashtag_string(platform);
					const platformCustomHook = current_workspace.social_caption?.hook_platforms.includes(platform) ? customHook : null;
					data[platform] = { ...data[platform], caption: GenerateCaptionText(platform, platformCustomHook || socialHook, steps, socialCTA, platformHashtagString) };
				}

				onOk({
					title,
					due_date: formattedDate,
					category_id: categoryId || null,
					platforms,
					content_type_id: contentType || null,
					hook_id: hook || null,
					data,
					call_to_action_id: CTA || null,
				})
			}}
			onCancel={onCancel}
			visible={visible}
			footerPosition="end"
			closable
			title="Add social post"
			titleSize={5}
			titleSVGName="share"
		>
			<Form>
				<Container noPadding>
					<Container.Column span={6}>
						<Form.Item label="Social content idea">
							<Input value={title} onChange={(e) => setTitle(e.target.value)} />
						</Form.Item>
					</Container.Column>

					<Container.Column span={6}>
						<Form.Item label="Due date">
							<Input type="date" value={dueDate} onChange={(e) => setDueDate(e.target.value)} />
						</Form.Item>
					</Container.Column>

					<Container.Column span={12}>
						<Form.Item label="Select a social category">
							<div className={cx('social-post-categories')}>
								{current_workspace.categories.map((category) => (
									<CategoryCard
										key={category.id}
										className={{ 'active': category.id === categoryId }}
										category={category}
										onSelect={setCategory}
									/>
								))}
							</div>
						</Form.Item>
					</Container.Column>
					<Container.Column span={6}></Container.Column>
				</Container>
				<Container noPadding>
					<Container.Column span={4}>
						<Form.Item label="Type of content">
							<Select value={contentType} options={contentTypeOptions} onChange={(value) => {
								setContentType(value);
								// Update platforms to match content type
								const foundContentType = current_workspace.content_types.find((content_type) => (value === content_type.id));
								setPlatforms(foundContentType?.platforms.length > 0 ? foundContentType.platforms : []);
							}} />
						</Form.Item>
					</Container.Column>
					<Container.Column span={4}>
						<Form.Item label="Hook category">
							<Select value={hookGroup} options={Array.from(hookTypeOptions.values())} onChange={setHookGroup} />
						</Form.Item>
					</Container.Column>
					<Container.Column span={4}>
						<Form.Item label="Select hook prompt">
							<Select
								value={hook}
								options={filteredHookOptions}
								onChange={(value) => {
									setHook(value);
									const option = filteredHookOptions.find((option) => option.value === value);
									setCustomHook(option.label);
								}}
							/>
						</Form.Item>
					</Container.Column>
				</Container>
				<Form.Item label="Visualize your post elements (# of slides, storyboard, text, video clips, etc)">
					<Input type="textarea" onChange={(e) => setDesign(e.target.value)} value={design} />
				</Form.Item>
				{current_workspace.social_caption?.hook_platforms.some((caption_platform) => platforms.includes(caption_platform)) &&
					<Form.Item label="Customize Hook: (what’s the problem)">
						<Input type="textarea" onChange={(e) => setCustomHook(e.target.value)} value={customHook} />
					</Form.Item>
				}
				{current_workspace.social_caption?.transformation_platforms.some((caption_platform) => platforms.includes(caption_platform)) &&
					<Form.Item label="Engaging Content:">
						<Input type="textarea" onChange={(e) => setSteps(e.target.value)} value={steps} />
					</Form.Item>
				}
				{current_workspace.social_caption?.call_to_action_platforms.some((caption_platform) => platforms.includes(caption_platform)) &&
					<Form.Item label="Call-to-action (CTA):">
						<Select value={CTA} options={CTAOptions} onChange={setCTA} />
					</Form.Item>
				}
			</Form>
		</Modal>
	)
});

const ExpandedSocialPost = observer(({ social_post }) => {
	const current_workspace = rootStore.WorkspaceStore.current_workspace;

	const hashtagOptions = current_workspace.hashtag_groups.map((group) => ({
		label: group.name,
		value: group.id,
	}));

	return (
		<div>
			<Container noPadding>
				<Container.Column span={4}>
					<Form.Item key="design" label="Design">
						<Input type="textarea" onChange={(e) => social_post.setData('design', e.target.value)} value={social_post?.data?.design ?? ''} />
					</Form.Item>
					<Form.Item>
						<ContentLinks
							single_column
							campaign_content_links={social_post.campaign_content_links}
							platform="SOCIAL"
							addCampaignContentLink={social_post.addCampaignContentLink}
						/>
					</Form.Item>
				</Container.Column>
				{social_post.platforms.map((platform) => (
					<Container.Column key={platform} span={4}>
						{platform === 'YOUTUBE' &&
							<Form.Item key={platform + 'title'} label={<div className={cx('social-platform-label')}><SVG name={PlatformIconMap.get(platform)} /><span> Title:</span></div>}>
								<Input
									onChange={(e) => {
										if (rootStore.WorkspaceStore.current_workspace.getCharacterLimit('YOUTUBE').title >= e.target.value.length) {
											social_post.setData(platform, { title: e.target.value })
										} else {
											message({ type: 'error', text: 'You have reached the maxium character limit for this field.' });
										}
									}}
									value={social_post?.data[platform]?.title ?? ''}
									canCopy
								/>
								<div className={cx('character-limit-text')}>
									{rootStore.WorkspaceStore.current_workspace.getCharacterLimit('YOUTUBE').title - (social_post?.data[platform]?.title?.length ?? 0)} characters left
								</div>
							</Form.Item>}
						<Form.Item key={platform} label={<div className={cx('social-platform-label')}><SVG name={platform !== 'YOUTUBE' && PlatformIconMap.get(platform)} /><span> Caption:</span></div>}>
							<Input
								type="textarea"
								onChange={(e) => social_post.setData(platform, { caption: e.target.value })}
								value={social_post?.data[platform]?.caption ?? ''}
								canCopy
							/>
						</Form.Item>
						<Form.Item className={cx('hashtag-options')} labelPosition="row" label={<SVG name="tag" />}>
							<Select
								key={social_post?.data[platform]?.hashtag_group_id}
								options={hashtagOptions}
								value={social_post?.data[platform]?.hashtag_group_id ?? ''}
								onChange={(value) => social_post.selectHashtagGroup(platform, value)}
								size="small"
							/>
						</Form.Item>
					</Container.Column>
				))}
			</Container>
		</div>
	);
});

const SocialView = observer(({ campaign_social, permalink }) => {
	const [visibleSocialPost, setVisibleSocialPost] = useState(false);

	const list = campaign_social?.sorted_due_dates.map((social_post) => ({
		id: social_post.id,
		title: (<div className="social-post-title">
			<div className="social-post-due-date">
				<SVG className="mr-1" name="calendar_today" /> {social_post?.formated_due_date}
			</div>
			<div>
				{social_post.title}
			</div>
		</div>),
		actions: [
			<div className="social-post-actions" key="social_post_title">
				{social_post.category?.title}
			</div>,
			<div className="social-post-actions" key="social_post_platforms">
				{social_post.platforms?.map((platform) => <SVG key={platform} name={PlatformIconMap.get(platform)} />)}
			</div>,
			<div className="social-post-actions" key="social_post_content_type">
				{social_post.content_type?.name}
			</div>,
			<div className="social-post-actions" key="social_post_edit">
				<Button icon="edit" type="text" onClick={(e) => {
					e.stopPropagation();
					campaign_social.setSelectedPost(social_post.id);
					setVisibleSocialPost(true);
				}} />
				<Button
					onClick={(e) => {
						e.stopPropagation();
						campaign_social.deleteSocialPost(social_post.id)
					}}
					icon="delete"
					type="text"
				/>
			</div>
		],
		expandedView: <ExpandedSocialPost social_post={social_post} />,
	})) || [];

	return (
		<Container noPadding>
			<div className={cx('keyword-research-button')}>
				<span className={cx('permalink')}>
					{permalink}
				</span>
			</div>
			<List rows={list} className={cx('social-posts-list')} />
			<Button onClick={() => {
				campaign_social.setSelectedPost(undefined);
				setVisibleSocialPost(true);
			}} type="text" icon="add_box" className={cx('new-social-post-button')} />

			{visibleSocialPost && <SocialPostModal
				campaign_social={campaign_social}
				visible={visibleSocialPost}
				onOk={async (data) => {
					if (campaign_social.selected_social_post) {
						const confirmUpdate = await confirm({
							text: 'Are you sure you want to save this?',
							children: 'Continuing will regenerate each social media caption using the current text.',
							okText: 'Generate'
						});
						if (!confirmUpdate) return;
						campaign_social.updateSocialPost(data);
					} else {
						campaign_social.addSocialPost(data);
					}
					setVisibleSocialPost(false)
				}}
				onCancel={() => setVisibleSocialPost(false)}
			/>}
		</Container>
	)
});

export default SocialView;