import {observer} from 'mobx-react-lite';
import {Container, Form, Input, Button} from 'components';

import CampaignTasks from '../campaign-tasks/campaign-tasks.view';
import ContentLinks from '../../contentLinks';

import classNames from 'classnames/bind';
import styles from './email.scss';

const cx = classNames.bind(styles);

const EmailView = observer(({campaign_platform, permalink}) => {
  return (
    <Container noPadding>
      <Container.Column span={8}>
        <div className={cx('keyword-research-button')}>
          <a
            className={cx('permalink')}
            href={`https://${permalink}`}
            target="_blank"
          >
            {permalink}
          </a>
        </div>
        <div>
          <Form.Item label="Subject Line">
            <Input
              value={campaign_platform.data.subject}
              onChange={e => campaign_platform.data.setSubject(e.target.value)}
              type="text"
              canCopy
            />
            <div className={cx('character-limit-text')}>
              {campaign_platform.character_limit.title -
                (campaign_platform.data.subject?.length ?? 0)}{' '}
              characters left
            </div>
          </Form.Item>
          <Form.Item label="Email Content">
            <Input
              value={campaign_platform.data.content}
              onChange={e => campaign_platform.data.setContent(e.target.value)}
              type="textarea"
              canCopy
            />
          </Form.Item>
          <Form.Item>
            <ContentLinks
              campaign_content_links={campaign_platform.campaign_content_links}
              platform={campaign_platform.platform}
              addCampaignContentLink={campaign_platform.addCampaignContentLink}
            />
            <div className={cx('trash-container')}>
              <Button
                type="text"
                icon="delete"
                iconSize={20}
                onClick={e => {
                  e.stopPropagation();
                  campaign_platform.deletePlatform(campaign_platform.id);
                }}
                style={{padding: 0}}
              ></Button>
            </div>

          </Form.Item>
        </div>
      </Container.Column>
      <Container.Column span={4} className={cx('campaign-tasks-container')}>
        <CampaignTasks
          campaign_tasks={campaign_platform?.sorted_campaign_tasks}
        />
      </Container.Column>
    </Container>
  );
});

export default EmailView;
