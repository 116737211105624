import classNames from 'classnames/bind';
import styles from './progressBar.scss';
const cx = classNames.bind(styles);

const ProgressBar = ({ current }) => (
	<div className={cx('tejik-progress-bar')}>
		<div className={cx('tejik-progress-bar-container')}>
			<div className={cx('tejik-current-bar')} style={{ width: `${current}%`}}/>
		</div>
		{current}%
	</div>
);

export default ProgressBar;