import { Observer, observer } from 'mobx-react-lite';
import { ModelConnector } from 'stores';
import { Button, Container, Form, Info, Input, LinkedIdeas, List, Popover, RichText, PlatformSelection} from 'components';
import SVG from 'components/svg/svg';
import LinkedIdeasModal from 'modals/linkedIdeas/linkedIdeas';

import CampaignModel from './campaign.model';

import classNames from 'classnames/bind';
import styles from '../campaigns.scss';
const cx = classNames.bind(styles);

const Campaign = observer(({ model }) => {
  return (
    <Container
		headerClassName={cx('campaign-header-container')}
		title={
			<Input
			hidden
			size="large"
			value={model.campaign?.idea?.title}
			onChange={e => model.campaign?.idea.setTitle(e.target.value)}
			/>
		}
		titleSize={4}
		isLoading={model.isLoading}
		actions={[
			<div key="calendar-date-range" className="flex-center">
			<SVG name="calendar_today" className="mr-1" />
			<span className={'date-range-span'}>{model.campaign?.date_range}</span>
			</div>,
			<Popover key="settings-popup" width="320px" content={
				<Form>
					<Form.Item label="Platforms:">
						<PlatformSelection
							platforms={model.platformArray}
							togglePlatform={model.addCampaignPlatform}
							workspacePlatforms={model.workspacePlatforms}
							showSocialOption={true}
						/>
					</Form.Item>
					<Form.Item label="Permalink/slug (URL):">
						<Observer>
						{() => (
							<Input
							placeholder="url-slug-based-on-title"
							value={model.campaign?.url_slug ?? ''}
							onChange={e => model.campaign.setUrlSlug(e.target.value)}
							size="small"
							/>
						)}
						</Observer>
					</Form.Item>
					<Form.Item label="Linked Content:">
						<LinkedIdeas
						ideas={model.campaign?.idea.linked_ideas ?? {}}
						onAddLinkedIdea={model.openLinkIdeasModal}
						/>
					</Form.Item>
					<hr />
					<Form.Item>
						<Button
						icon="delete"
						type="text"
						onClick={() =>
							model.campaign.deleteCampaign(() => {
							model.props.navigate('/campaigns', { replace: true });
							})
						}
						>
						Delete campaign
						</Button>
					</Form.Item>
				</Form>
			}>
				<Button key="settings-button" type="secondary" shape="hollow" icon="build"/>
			</Popover>,
			<Button key="close-button" type="text" icon="close" onClick={() => model.props.navigate('/campaigns')}/>
      	]}
      	>
		<LinkedIdeasModal
          onOk={model.hideLinkIdeasModal}
          onCancel={model.hideLinkIdeasModal}
          visible={model.visibleLinkIdeasModal}
          idea={model.campaign?.idea}
        />
		<Form.Item className={cx('campaign-idea-notes')}>
			<RichText
			initialState={
				(model.campaign?.idea?.rich_notes ||
				model.campaign?.idea?.notes) ??
				''
			}
			onChange={state => model.campaign?.idea?.setNotes(state)}
			/>
		</Form.Item>
		<List rows={model.platformList} className={cx('campaigns-list')} />
		<Info infoKey="campaign" />
    </Container>
  );
});

export default ModelConnector(Campaign, CampaignModel);
