import { observer } from "mobx-react-lite";

import classNames from 'classnames/bind';
import styles from './campaign-tasks.scss';
import { Checkbox, Title } from "components";
const cx = classNames.bind(styles);

const CampaignTasks = observer(({ campaign_tasks }) => (
	<div className={cx('tejik-campaign-tasks')}>
		<Title className={cx('tejik-campaign-tasks-title')} size={6}>Tasks</Title>
		{campaign_tasks.map((campaign_task) => (
			<div key={campaign_task.id} className={cx('tejik-campaign-task')}>
				<Checkbox
					label={campaign_task.task.name}
					checked={campaign_task.completed}
					onChange={(e) => campaign_task.setCompleted(e.target.checked)}
				/>
			</div>
		))}
	</div>
));

export default CampaignTasks;