export const closePopover = (e) => {
  if (e) {
    const event = new CustomEvent('force-close', {
      bubbles: true,
    });
    e.target.dispatchEvent(event);
  }
}

export const PlatformOrderList = [
  'BLOG', 'PODCAST', 'YOUTUBE', 'EMAIL', 'INSTAGRAM', 'FACEBOOK', 'PINTEREST', 'TIKTOK', 'LINKEDIN', 'TWITTER', 'SOCIAL'
]

export const PlatformSelectionList = [
  {icon: 'computer', label: 'Blog Post', value: 'BLOG'},
  {icon: 'podcasts', label: 'Podcast', value: 'PODCAST'},
  {icon: 'youtube', label: 'YouTube', value: 'YOUTUBE'},
  {icon: 'email', label: 'Email', value: 'EMAIL'},
  {icon: 'instagram', label: 'Instagram', value: 'INSTAGRAM'},
  {icon: 'facebook', label: 'Facebook', value: 'FACEBOOK'},
  {icon: 'pinterest', label: 'Pinterest', value: 'PINTEREST'},
  {icon: 'tiktok', label: 'TikTok', value: 'TIKTOK'},
  {icon: 'linkedin', label: 'LinkedIn', value: 'LINKEDIN'},
  {icon: 'twitter', label: 'Twitter', value: 'TWITTER'},
];

export const OrganizationRoleMap = new Map([
  ['OWNER', 'Owner'],
  ['ADMIN', 'Admin'],
  ['MEMBER', 'Member'],
  ['GUEST', 'Guest'],
]);

export const OrganizationPaymentStatusMap = new Map([
  ['PAID', 'Subscribed'],
  ['UNPAID', 'Unsubscribed'],
  ['NOT_SETUP', 'Not Setup'],
]);

export const PlatformIconMap = new Map([
  ['BLOG', 'computer'],
  ['PODCAST', 'podcasts'],
  ['YOUTUBE', 'youtube'],
  ['EMAIL', 'email'],
  ['INSTAGRAM', 'instagram'],
  ['FACEBOOK', 'facebook'],
  ['PINTEREST', 'pinterest'],
  ['TIKTOK', 'tiktok'],
  ['LINKEDIN', 'linkedin'],
  ['TWITTER', 'twitter'],
  ['SOCIAL', 'share'],
]);

export const PlatformLabelMap = new Map([
  ['BLOG', 'Blog Post'],
  ['PODCAST', 'Podcast'],
  ['YOUTUBE', 'YouTube'],
  ['EMAIL', 'Email'],
  ['INSTAGRAM', 'Instagram'],
  ['FACEBOOK', 'Facebook'],
  ['PINTEREST', 'Pinterest'],
  ['TIKTOK', 'TikTok'],
  ['LINKEDIN', 'LinkedIn'],
  ['TWITTER', 'Twitter'],
  ['SOCIAL', 'Social'],
]);

export const ContentLinkIconMap = new Map([
  ['DOCUMENT', 'description'],
  ['PHOTO', 'image'],
  ['VIDEO', 'movie'],
  ['FILE', 'folder'],
])

export const StatusPlatformSelectionList = [
  {icon: 'computer', label: 'Blog Post', value: 'BLOG'},
  {icon: 'podcasts', label: 'Podcast', value: 'PODCAST'},
  {icon: 'youtube', label: 'YouTube', value: 'YOUTUBE'},
  {icon: 'email', label: 'Email', value: 'EMAIL'},
  {icon: 'share', label: 'Social', value: 'SOCIAL'},
  {icon: 'pinterest', label: 'Pinterest', value: 'PINTEREST'},
];

export const IsTaskPlatform = (platform) => ['BLOG', 'PODCAST', 'YOUTUBE', 'EMAIL'].includes(platform);
export const IsStatusPlatform = (platform) => ['BLOG', 'PODCAST', 'YOUTUBE', 'EMAIL', 'PINTEREST', 'SOCIAL'].includes(platform);
export const IsSocialMediaPlatform = (platform) => ['INSTAGRAM', 'FACEBOOK', 'TIKTOK', 'LINKEDIN', 'TWITTER', 'SOCIAL'].includes(platform);
export const IsSocialPlatform = (platform) => ['INSTAGRAM', 'FACEBOOK', 'TIKTOK', 'LINKEDIN', 'TWITTER', 'PINTEREST', 'SOCIAL'].includes(platform);

export const ColorOptions = [
  '#F20089',
  '#F94144',
  '#FB888A',
  '#FFD4C2',
  '#FF8552',
  '#F9C74F',
  '#FF9E00',
  '#E36414',
  '#683C68',
  '#6D23B6',
  '#B57DB5',
  '#DCCFEC',
  '#ADD8EB',
  '#AFD4D3',
  '#8FA998',
  '#4D908E',
  '#396A68',
  '#184C63',
  '#277DA1',
  '#E6CCB2',
  '#7F5539',
  '#D8D8D8',
  '#000000',
];

export const ColorForeground = new Map([
  ['#F20089', '#000000'],
  ['#F94144', '#000000'],
  ['#FB888A', '#000000'],
  ['#FFD4C2', '#000000'],
  ['#FF8552', '#000000'],
  ['#F9C74F', '#000000'],
  ['#FF9E00', '#000000'],
  ['#E36414', '#000000'],
  ['#683C68', '#FFFFFF'],
  ['#6D23B6', '#FFFFFF'],
  ['#B57DB5', '#000000'],
  ['#DCCFEC', '#000000'],
  ['#ADD8EB', '#000000'],
  ['#AFD4D3', '#000000'],
  ['#8FA998', '#000000'],
  ['#4D908E', '#000000'],
  ['#396A68', '#FFFFFF'],
  ['#184C63', '#FFFFFF'],
  ['#277DA1', '#FFFFFF'],
  ['#E6CCB2', '#000000'],
  ['#7F5539', '#FFFFFF'],
  ['#D8D8D8', '#000000'],
  ['#000000', '#FFFFFF'],
])

// SOCIAL SETTINGS

export const HookLabelMap = new Map([
  ['TUTORIAL', 'Tutorial Hooks'],
  ['STORY', 'Story Hooks'],
  ['RESULTS', 'Results Hooks'],
  ['CALLOUT', 'Callout Hooks'],
  ['NURTURE', 'Nurture Hooks'],
]);

export const DateFormatter = (date_string, format) => {
  if (!date_string) return 'Invalid Date';

  const options = {
    'MD': { 		// Month DD
      month: 'short',
      day: 'numeric',
    }
  }[format]
  const date = new Date(date_string);
  return date.toLocaleDateString('en-US', options);
};

export const PlatformCharacterLimitSettings = new Map([
  [
    'BLOG',
    {
      title: {
        label: 'Title',
        default_character_limit: '60',
        ideal_character_limit: 'up to 60',
        value: 60,
      },
      description: {
        label: 'Meta Description',
        default_character_limit: '160',
        ideal_character_limit: 'around 160',
        value: 160,
      },
    },
  ],
  [
    'PODCAST',
    {
      title: {
        label: 'Title',
        default_character_limit: '26',
        ideal_character_limit: 'up to 26',
        value: 26,
      },
      description: {
        label: 'Description',
        default_character_limit: '4000',
        ideal_character_limit: 'up to 60',
        value: 4000,
      },
    },
  ],
  [
    'YOUTUBE',
    {
      title: {
        label: 'Title',
        default_character_limit: '100',
        ideal_character_limit: 'up to 70',
        value: 100,
      },
      description: {
        label: 'Description',
        default_character_limit: '5000',
        ideal_character_limit: 'up to 157',
        value: 5000,
      },
    },
  ],
  [
    'EMAIL',
    {
      title: {
        label: 'Subject line',
        default_character_limit: '60',
        ideal_character_limit: 'up to 60',
        value: 60,
      },
    },
  ],
  [
    'PINTEREST',
    {
      title: {
        label: 'Title',
        default_character_limit: '100',
        ideal_character_limit: 'up to 40',
        value: 100,
      },
      description: {
        label: 'Description',
        default_character_limit: '500',
        ideal_character_limit: 'up to 200',
        value: 500,
      },
    },
  ],
  [
    'INSTAGRAM',
    {
      description: {
        label: 'Caption',
        default_character_limit: '2200',
        ideal_character_limit: 'up to 150',
        value: 2200,
      },
    },
  ],
  [
    'FACEBOOK',
    {
      description: {
        label: 'Caption',
        default_character_limit: '33000',
        ideal_character_limit: 'up to 80',
        value: 33000,
      },
    },
  ],
  [
    'TIKTOK',
    {
      description: {
        label: 'Caption',
        default_character_limit: '2200',
        ideal_character_limit: 'up to 150',
        value: 2200,
      },
    },
  ],
  [
    'LINKEDIN',
    {
      description: {
        label: 'Headline',
        default_character_limit: '100',
        ideal_character_limit: 'up to 49',
        value: 100,
      },
    },
  ],
  [
    'TWITTER',
    {
      description: {
        label: 'Tweet',
        default_character_limit: '280',
        ideal_character_limit: 'up to 100',
        value: 280,
      },
    },
  ],
]);
