import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Container, Form, Input, KeywordResearch, Menu, Modal, Popover, Select, Title } from "components";

import classNames from 'classnames/bind';
import styles from './pinterest.scss';
import ContentLinks from "../../contentLinks";
const cx = classNames.bind(styles);

const KeywordResearchModal = observer(({ additional_keywords, campaign_pinterest, onOk, onCancel, visible }) => (
	<Modal
		className={cx('new-pinterest-pin-modal')}
		width="80%"
		onOk={onOk}
		onCancel={onCancel}
		visible={visible}
		footer={[]}
		closable
		title="Add pinterest keywords"
		titleSize={5}
		titleSVGName="pinterest"
	>
		<KeywordResearch
			additional_keywords={additional_keywords}
			keyword_research={campaign_pinterest.keyword_research}
			title="Pinterest Keyword Research"
			platform="PINTEREST"
		/>
	</Modal>
));

const ConfigurePinterestPinModal = observer(({ additional_keywords, campaign_pinterest, pinterest_pin, onOk, onCancel, visible }) => (
	<Modal
		className={cx('configure-pinterest-pin-modal')}
		width="80%"
		onOk={onOk}
		onCancel={onCancel}
		visible={visible}
		footer={[]}
		closable
		title="Add pinterest keywords"
		titleSize={5}
		titleSVGName="pinterest"
	>
		<Container noPadding>
			<Container.Column span={6}>
				<KeywordResearch
					additional_keywords={additional_keywords}
					keyword_research={campaign_pinterest.keyword_research}
					title="Pinterest Keyword Research"
					platform="PINTEREST"
				/>
			</Container.Column>
			<Container.Column span={6}>
				<PinterestPin pinterest_pin={pinterest_pin} onGenerate={onOk}/>
			</Container.Column>
		</Container>

	</Modal>
))

const UnconfiguredPin = observer(({ additional_keywords, campaign_pinterest, pinterest_pin }) => {
	const [visibleConfigurationModal, setVisibleConfigurationModal] = useState(false);

	return (
		<>
			<Container noPadding fillHeight className="not-configured-pin">
				<Container.Column span={12}>
					<Title size={6}>
						Pinterest Content
					</Title>
					<p className="mb-2">(based on scheduling targets)</p>
					<div className="not-configured-pin-actions">
						<Button type="text" icon="query_stats" iconSize={36} onClick={() => setVisibleConfigurationModal(true)} />
						<div className="divider" />
						<Button type="text" icon="delete" iconSize={36} onClick={pinterest_pin.deletePin}/>
					</div>
				</Container.Column>
			</Container>
			{visibleConfigurationModal && <ConfigurePinterestPinModal
				additional_keywords={additional_keywords}
				campaign_pinterest={campaign_pinterest}
				pinterest_pin={pinterest_pin}
				visible={visibleConfigurationModal}
				onOk={() => setVisibleConfigurationModal(false)}
				onCancel={() => setVisibleConfigurationModal(false)}
			/>}
		</>
	);
});

const PinterestPin = observer(({ pinterest_pin, onGenerate }) => {
	return (
		<Container noPadding>
			<Container.Column span={12}>
				<Form.Item label="Due date">
					<Input type="date" onChange={(e) => pinterest_pin.setDueDate(e.target.value)} value={pinterest_pin?.formated_due_date_input} />
				</Form.Item>
				<Form.Item label="Pin Title">
					<Input onChange={(e) => pinterest_pin.setTitle(e.target.value)} value={pinterest_pin?.title || ''} canCopy />
					<div className={cx('character-limit-text')}>
						{pinterest_pin.character_limit?.title - (pinterest_pin.title?.length ?? 0)} characters left
					</div>
				</Form.Item>
				<Form.Item label="Pin Description">
					<Input type="textarea" onChange={(e) => pinterest_pin.setDescription(e.target.value)} value={pinterest_pin?.description || ''} canCopy />
					<div className={cx('character-limit-text')}>
						{pinterest_pin.character_limit?.description - (pinterest_pin.description?.length ?? 0)} characters left
					</div>
				</Form.Item>
				<Form.Item label="Content Type">
					<Select
						value={pinterest_pin.pinterest_content_type}
						options={[
							{
								label: 'Image Pin',
								value: 'IMAGE',
							},
							{
								label: 'Video Pin',
								value: 'VIDEO',
							},
							{
								label: 'Static Pin',
								value: 'STATIC',
								hidden: true,
							},
							{
								label: 'Idea Pin',
								value: 'IDEA',
								hidden: true,
							},
						]}
						onChange={pinterest_pin.setPinterestContentType}
					/>
				</Form.Item>
				{!pinterest_pin.is_configured &&
					<Button onClick={() => {
						pinterest_pin.setIsConfigured(true);
						onGenerate();
					}}>
						Generate
					</Button>
				}
				{pinterest_pin.is_configured && <ContentLinks
					single_column
					campaign_content_links={pinterest_pin.campaign_content_links}
					platform="PINTEREST"
					addCampaignContentLink={pinterest_pin.addCampaignContentLink}
				/>}
			</Container.Column>
		</Container>
	);
});

const PinterestView = observer(({ additional_keywords, campaign_pinterest, permalink }) => {
	const [visiblePinModal, setVisiblePinModal] = useState(false);

	return (
		<Container noPadding>
			<div className={cx('keyword-research-button')}>
				<a className={cx('permalink')} href={`https://${permalink}`} target="_blank">
					{permalink}
				</a>
			</div>
			<div className={cx('pinterest-post-container')}>
				{campaign_pinterest?.sorted_due_dates.map((pinterest_pin) => (
					<div key={pinterest_pin.id} className={cx('pinterest-post')}>
						<Popover content={<Menu items={[
							{
								icon: 'query_stats',
								text: 'Keyword Research',
								action: () => {
									campaign_pinterest.setSelectedPin(pinterest_pin.id);
									setVisiblePinModal(true);
								},
							},
							{
								icon: 'delete',
								text: 'Delete',
								action: pinterest_pin.deletePin,
							}
						]} />}>
							{pinterest_pin.is_configured ? <Button icon="more_vert" type="text" /> :
								<Button icon="warning" type="text" onClick={(e) => e.stopPropagation()} />
							}
						</Popover>
						{pinterest_pin.is_configured ?
							<PinterestPin pinterest_pin={pinterest_pin} />
							:
							<UnconfiguredPin
								additional_keywords={additional_keywords}
								campaign_pinterest={campaign_pinterest}
								pinterest_pin={pinterest_pin}
							/>
						}

					</div>
				))}
				<Button onClick={campaign_pinterest.addPin} type="text" icon="add_box" iconSize="28px" />
			</div>

			{visiblePinModal && <KeywordResearchModal
				additional_keywords={additional_keywords}
				campaign_pinterest={campaign_pinterest}
				visible={visiblePinModal}
				onOk={() => setVisiblePinModal(false)}
				onCancel={() => setVisiblePinModal(false)}
			/>}
		</Container>
	)
});

export default PinterestView;