import request from 'api/request';
import debounce from 'lodash.debounce';
import {flow, isAlive, types} from 'mobx-state-tree';
import {CampaignModel} from 'models/campaign.model';
import {ColorForeground, PlatformIconMap} from 'utils/utils';
import {PlatformLabelMap} from 'utils/utils';

import React from 'react';
import {
  Button,
  Container,
  DatePicker,
  LinkedIdeas,
  Menu,
  UserImage
} from 'components';
import SVG from 'components/svg/svg';
import ProgressBar from './progressBar';

import classNames from 'classnames/bind';
import styles from './campaigns.scss';
import {rootStore} from 'stores';

const cx = classNames.bind(styles);

const RenderCampaignPlatform = ({item}) => (
  <div className={cx('campaign-platform-container')}>
    <div className={cx('campaign-platform-channel-name')}>
      {item.channel_name}
    </div>
    <div>
      {item.sponsored_content && (
        <Button key="flag" className="text-primary" type="text" icon="flag"/>
      )}
    </div>
    <div
      style={{
        backgroundColor: item.status?.color,
        color: ColorForeground.get(item.status?.color),
        padding: '0.25em 1em'
      }}
    >
      {item.status?.name}
    </div>
    {item.status?.assignee ? (
      <UserImage user={item.status.assignee}/>
    ) : (
      <div></div>
    )}
    <div className="campaign-platform-item-date">
      <SVG name="calendar_today" className="mr-1"/>
      {item.due_date}
    </div>
    <ProgressBar current={item.percentage}/>
  </div>
);

const RenderedExpandedView = (params) => {

  const {campaign, onItemDeleted} = params;

  if (!isAlive(campaign)) return;

  const menuItems = [
    ...campaign.campaign_platforms.map(campaign_platform => ({
      icon: PlatformIconMap.get(campaign_platform.platform),
      text: (
        <RenderCampaignPlatform
          item={{
            sponsored_content: campaign_platform.sponsored_content,
            channel_name: PlatformLabelMap.get(campaign_platform.platform),
            percentage: campaign_platform.percentage_complete,
            due_date: campaign_platform.formated_due_date,
            status: campaign_platform?.status
          }}
        />
      ),
      to: `/campaigns/${campaign.id}`
    }))
  ];

  if (campaign.campaign_social) {
    menuItems.push({
      icon: PlatformIconMap.get('SOCIAL'),
      text: (
        <RenderCampaignPlatform
          item={{
            channel_name: PlatformLabelMap.get('SOCIAL'),
            percentage: campaign.campaign_social.percentage_complete,
            due_date: campaign.campaign_social.formated_due_date,
            status: campaign.campaign_social?.status
          }}
        />
      ),
      to: `/campaigns/${campaign.id}`
    });
  }

  if (campaign.campaign_pinterest) {
    menuItems.push({
      icon: PlatformIconMap.get('PINTEREST'),
      text: (
        <RenderCampaignPlatform
          item={{
            channel_name: PlatformLabelMap.get('PINTEREST'),
            percentage: campaign.campaign_pinterest.percentage_complete,
            due_date: campaign.campaign_pinterest.formated_due_date,
            status: campaign.campaign_pinterest?.status
          }}
        />
      ),
      to: `/campaigns/${campaign.id}`
    });
  }

  return (
    <Container noPadding>
      <Container.Column span={8}>
        <Menu className={cx('campaign-platforms')} items={menuItems}/>
      </Container.Column>
      <Container.Column span={1}></Container.Column>
      <Container.Column span={3}>
        <div className={cx('campaign-third-col-container')}>
          <div>
            <p className="mb-1">Linked Ideas</p>
            <LinkedIdeas ideas={campaign.idea.linked_ideas}/>
          </div>
          <div className={cx('campaign-delete-btn-container')}>
            <Button
              icon="delete"
              type="text"
              iconSize={20}
              onClick={() =>
                campaign.deleteCampaign(() => {
                  onItemDeleted();
                })
              }
              style={{padding: 0}}/>
          </div>
        </div>

      </Container.Column>

    </Container>
  );
};

const {array, boolean, enumeration, model, optional, string, number, frozen} = types;
// Model
const CampaignsModel = model('CampaignsModel', {
  campaigns: array(CampaignModel),
  isLoading: optional(boolean, true),
  campaignListState: optional(
    enumeration('CampaignListState', ['active', 'closed']),
    'active'
  ),
  visibleCreateIdeaModal: optional(boolean, false),
  visibleFilterModal: optional(boolean, false),
  toggleMeFilterApplied: optional(boolean, false),
  searchTerm: optional(string, ''),
  filterCount: optional(number, 0),
  filters: frozen({})
})
  .views(self => ({
    get baseURL() {
      return `${self.WorkspaceStore.current_workspace.baseURL}/campaigns`;
    },
    get campaignList() {
      const mapFunc = campaign => ({
        id: campaign.id,
        // title: campaign.title,
        title: (
          <div className="d-flex">
            {campaign.title}
            <div style={{flexGrow: 1}}/>
            {campaign.recycle_at && <SVG name="recycle" fontSize={16}/>}
            {campaign.sponsored_content && (
              <SVG className="text-primary ml-1" name="flag" fontSize={16}/>
            )}
          </div>
        ),
        onTitleClick: () => self.props.navigate(`/campaigns/${campaign.id}`),
        actions: [
          <div
            key="campaign_date"
            className={cx('campaign-date-picker', 'fixed-width')}
            onClick={e => e.stopPropagation()}
          >
            {campaign.due_date && (
              <DatePicker
                selected={new Date(campaign.due_date)}
                onChange={campaign.setDueDate}
                customInput={
                  <div className={cx('campaign-date-picker-input')}>
                    <SVG className="mr-1" name="calendar_today"/>{' '}
                    {campaign.formated_due_date}
                  </div>
                }
              />
            )}
          </div>
        ],
        expandedView: (
          <RenderedExpandedView
            key={campaign.id}
            onItemDeleted={() => {
              self.fetchCampaigns(rootStore.WorkspaceStore.campaignFilters);
            }}
            campaign={campaign}
            navigate={self.props.navigate}
          />
        )
      });
      if (self.campaignListState === 'closed') {
        return self.campaigns
          .filter(campaign => campaign.status?.type === 'CLOSED')
          .sort((a, b) => new Date(a.due_date) - new Date(b.due_date))
          .map(mapFunc);
      }

      return self.campaigns
        .filter(campaign => campaign.status?.type !== 'CLOSED')
        .sort((a, b) => new Date(a.due_date) - new Date(b.due_date))
        .map(mapFunc);
    }
  }))
  .actions(self => ({
    afterCreate() {
      const initialFilters = rootStore.WorkspaceStore.campaignFilters;
      self.fetchCampaigns(initialFilters);
    },
    searchCampaigns: debounce(() => self.fetchCampaigns(), 700),
    fetchCampaigns: flow(function* (initialFilters) {
      self.isLoading = true;
      try {
		    self.fillWithStoredFilters();
        const filters = { filterApplied: true, ...(self.filters ?? initialFilters)};

        const {data} = yield request.get(self.baseURL, {
          params: {
            search_term: self.searchTerm,
            ...filters
          }
        });

        if(self.filters ?? initialFilters) self.setFilters(filters);
        self.setCampaigns([]);
        self.setCampaigns(data);
      } catch (err) {
        console.error(err);
      } finally {
        self.isLoading = false;
      }
    }),
    setCampaigns(data) {
      self.campaigns = data;
    },
    setFilters(filters) {
      self.filters = filters;
      self.filterCount = (filters?.users?.length > 0 ? 1 : 0) + (filters?.platforms?.length > 0 ? 1 : 0) + (filters.sponsoredContent ? 1 : 0) + (filters.recycledContent ? 1 : 0);
    },
    setSearchTerm(searchTerm) {
      self.isLoading = true;
      self.searchTerm = searchTerm;
      self.searchCampaigns();
    },
    setCampaignListState(campaignListState) {
      self.campaignListState = campaignListState;
    },
    showCreateIdeaModal() {
      self.visibleCreateIdeaModal = true;
    },
    hideCreateIdeaModal() {
      self.visibleCreateIdeaModal = false;
    },
    toggleMeFilter() {
      self.toggleMeFilterApplied = !self.toggleMeFilterApplied;

      if (self.toggleMeFilterApplied) {
        self.filters = {...self.filters, users: [rootStore.AuthStore.user]};
      } else {
        self.filters = {...self.filters, users: []};
      }

      localStorage.setItem('CAMPAIGNS-FILTER-DATA', JSON.stringify(self.filters));
      self.fetchCampaigns(null, true);
    },
    filterModalClick(filters) {
      if (filters?.filterApplied) {
        self.toggleMeFilterApplied = false;
        self.setFilters(filters);
        self.fetchCampaigns();
      }
      self.visibleFilterModal = !self.visibleFilterModal;
    },
	fillWithStoredFilters(){
		const storedData = localStorage.getItem('CAMPAIGNS-FILTER-DATA');
		if(storedData) {
			const filters = JSON.parse(storedData);
			self.setFilters(filters);
		}
	},
  }));

export default CampaignsModel;
