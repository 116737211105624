import request from 'api/request';
import { Button, DatePicker, Popover, StatusSelection, UserImage} from 'components';
import {flow, types, destroy, getSnapshot} from 'mobx-state-tree';
import {CampaignModel} from 'models/campaign.model';
import { ColorForeground, PlatformIconMap, PlatformLabelMap, PlatformOrderList } from 'utils/utils';

import BlogView from './campaign-platform/blog.view';
import EmailView from './campaign-platform/email.view';
import PinterestView from './campaign-platform/pinterest.view';
import PodcastView from './campaign-platform/podcast.view';
import SocialView from './campaign-platform/social.view';
import YoutubeView from './campaign-platform/youtube.view';

import classNames from 'classnames/bind';
import styles from '../campaigns.scss';
import SVG from 'components/svg/svg';

import {Modal} from 'components';

const cx = classNames.bind(styles);

const {boolean, maybeNull, model, optional} = types;
const {confirm, message} = Modal;

const RenderedExpandedView = ({campaign_platform, permalink}) =>
  ({
    BLOG: (
      <BlogView campaign_platform={campaign_platform} permalink={permalink}/>
    ),
    PODCAST: (
      <PodcastView
        campaign_platform={campaign_platform}
        permalink={permalink}
      />
    ),
    YOUTUBE: (
      <YoutubeView
        campaign_platform={campaign_platform}
        permalink={permalink}
      />
    ),
    EMAIL: (
      <EmailView campaign_platform={campaign_platform} permalink={permalink}/>
    )
  }[campaign_platform.platform]);

const CampaignsModel = model('CampaignsModel', {
  campaign: maybeNull(CampaignModel),
  isLoading: optional(boolean, true),
  visibleLinkIdeasModal: optional(boolean, false)
})
  .views(self => ({
    get baseURL() {
      return `${self.WorkspaceStore.current_workspace.baseURL}/campaigns/${self.props.params.campaign_id}`;
    },
	get workspacePlatforms() {
		const allowed = ['BLOG','EMAIL', 'PODCAST', 'YOUTUBE', 'PINTEREST', 'SOCIAL'];
		const result = [...self.WorkspaceStore.current_workspace.platforms.filter(p => allowed.includes(p)), 'SOCIAL'];

		const platformsSorted = result.sort((a, b) => {
      const indexA = PlatformOrderList.findIndex(type => a === type);
      const indexB = PlatformOrderList.findIndex(type => b === type);
      return indexA - indexB;
		});
		if (self.WorkspaceStore?.current_workspace?.primary_platform) {
      const index = platformsSorted.findIndex(p => p === self.WorkspaceStore.current_workspace.primary_platform);
      platformsSorted.unshift(platformsSorted.splice(index, 1)[0]);
		}
		return platformsSorted;
	},
	get platformArray() {
		if (!self.campaign) {
		  return [];
		}

		const platforms = self.campaign?.campaign_platforms.map(p => { return p.platform });
		if(self.campaign?.campaign_social) platforms.push('SOCIAL');
		if(self.campaign?.campaign_pinterest) platforms.push('PINTEREST');
		
		return platforms;
	},
    get platformList() {
      if (!self.campaign) {
        return []
      }

      const platforms = [];
      if (self.campaign.campaign_platforms) {
        platforms.push(
          ...self.campaign?.campaign_platforms.map(campaign_platform => ({
            id: campaign_platform.id,
            platform: campaign_platform.platform,
            title: (
              <div className="d-flex">
                <SVG
                  name={PlatformIconMap.get(campaign_platform.platform)}
                  className="mr-1"
                />
                {PlatformLabelMap.get(campaign_platform.platform)}
                <div style={{flexGrow: 1}}/>
                {campaign_platform.recycle_at && (
                  <SVG name="recycle" fontSize={16}/>
                )}
              </div>
            ),
            actions: [
              campaign_platform.sponsored_content && (
                <Button
                  key="flag"
                  className="text-primary"
                  type="text"
                  icon="flag"
                />
              ),
              <div
                key="campaign_date"
                className={cx('campaign-date-picker')}
                onClick={e => e.stopPropagation()}
              >
                {campaign_platform.due_date && (
                  <DatePicker
                    selected={new Date(campaign_platform.due_date)}
                    onChange={campaign_platform.setDueDate}
                    customInput={
                      <div className={cx('campaign-date-picker-input')}>
                        <SVG className="mr-1" name="calendar_today"/>{' '}
                        {campaign_platform.formated_due_date}
                      </div>
                    }
                  />
                )}
              </div>,
              <div
                key="campaign_status"
                className={cx('campaign-actions', 'status-button')}
                style={{
                  width: 250,
                  backgroundColor: campaign_platform.status?.color,
                  color: ColorForeground.get(campaign_platform.status?.color)
                }}
              >
                <Popover
                  content={
                    <StatusSelection
                      statusOptions={self.WorkspaceStore.current_workspace.statusOptions(
                        campaign_platform.platform
                      )}
                      onSelect={campaign_platform.setStatus}
                      value={campaign_platform.status?.id}
                    />
                  }
                  width={300}
                >
                  {campaign_platform.status?.name}
                </Popover>
              </div>,
              <div
                key="campaign_assignee"
                className={cx('campaign-actions', 'campaign-assignee')}
              >
                <UserImage user={campaign_platform.status?.assignee}/>
              </div>
            ],
            expandedView: (
              <RenderedExpandedView
                campaign_platform={campaign_platform}
                permalink={self.campaign.permalink}
              />
            )
          }))
        );
      }

      if (self.campaign.campaign_social) {
        const statusOptions =
          self.WorkspaceStore.current_workspace.statusOptions(['SOCIAL']);

        const campaign_social = self.campaign.campaign_social;
        platforms.push({
          id: campaign_social.id,
          platform: 'SOCIAL',
          title: (
            <div className="d-flex">
              <SVG name={PlatformIconMap.get('SOCIAL')} className="mr-1"/>
              {PlatformLabelMap.get('SOCIAL')}
              <div style={{flexGrow: 1}}/>
              {campaign_social.recycle_at && (
                <SVG name="recycle" fontSize={16}/>
              )}
            </div>
          ),
          actions: [
            <div
              key="campaign_date"
              className={cx('campaign-date-picker')}
              onClick={e => e.stopPropagation()}
            >
              {campaign_social?.due_date && (
                <DatePicker
                  selected={new Date(campaign_social?.due_date)}
                  onChange={campaign_social.updateDueDates}
                  customInput={
                    <div className={cx('campaign-date-picker-input')}>
                      <SVG className="mr-1" name="calendar_today"/>{' '}
                      {campaign_social?.formated_due_date}
                    </div>
                  }
                />
              )}
            </div>,
            <div
              key="campaign_status"
              className={cx('campaign-actions', 'status-button')}
              style={{
                width: 250,
                backgroundColor: campaign_social?.status?.color,
                color: ColorForeground.get(campaign_social?.status?.color)
              }}
            >
              <Popover
                content={
                  <StatusSelection
                    statusOptions={statusOptions}
                    onSelect={campaign_social?.setStatus}
                    value={campaign_social?.status?.id}
                  />
                }
                width={300}
              >
                {campaign_social?.status?.name}
              </Popover>
            </div>,
            <div
              key="campaign_assignee"
              className={cx('campaign-actions', 'campaign-assignee')}
            >
              <UserImage user={campaign_social.status?.assignee}/>
            </div>
          ],
          expandedView: (
            <SocialView
              campaign_social={self.campaign.campaign_social}
              permalink={self.campaign.permalink}
            />
          )
        });
      }

      if (self.campaign.campaign_pinterest) {
        const statusOptions =
          self.WorkspaceStore.current_workspace.statusOptions('PINTEREST');
        const additional_keywords = self.campaign.campaign_platforms.find(
          cp => cp.platform === 'BLOG'
        )?.keyword_research?.keywords;

        const campaign_pinterest = self.campaign.campaign_pinterest;
        platforms.push({
          id: campaign_pinterest.id,
          platform: 'PINTEREST',
          title: (
            <div className="d-flex">
              <SVG name={PlatformIconMap.get('PINTEREST')} className="mr-1"/>
              {PlatformLabelMap.get('PINTEREST')}
              <div style={{flexGrow: 1}}/>
              {campaign_pinterest.recycle_at && (
                <SVG name="recycle" fontSize={16}/>
              )}
            </div>
          ),
          actions: [
            <div
              key="campaign_date"
              className={cx('campaign-date-picker')}
              onClick={e => e.stopPropagation()}
            >
              {campaign_pinterest?.due_date && (
                <DatePicker
                  selected={new Date(campaign_pinterest?.due_date)}
                  onChange={campaign_pinterest.updateDueDates}
                  customInput={
                    <div className={cx('campaign-date-picker-input')}>
                      <SVG className="mr-1" name="calendar_today"/>{' '}
                      {campaign_pinterest?.formated_due_date}
                    </div>
                  }
                />
              )}
            </div>,
            <div
              key="campaign_status"
              className={cx('campaign-actions', 'status-button')}
              style={{
                width: 250,
                backgroundColor: campaign_pinterest?.status?.color,
                color: ColorForeground.get(campaign_pinterest?.status?.color)
              }}
            >
              <Popover
                content={
                  <StatusSelection
                    statusOptions={statusOptions}
                    onSelect={campaign_pinterest?.setStatus}
                    value={campaign_pinterest?.status?.id}
                  />
                }
                width={300}
              >
                {campaign_pinterest?.status?.name}
              </Popover>
            </div>,
            <div
              key="campaign_assignee"
              className={cx('campaign-actions', 'campaign-assignee')}
            >
              <UserImage user={campaign_pinterest.status?.assignee}/>
            </div>
          ],
          expandedView: (
            <PinterestView
              additional_keywords={additional_keywords}
              campaign_pinterest={self.campaign.campaign_pinterest}
              permalink={self.campaign.permalink}
            />
          )
        });
      }

      const platformsSorted = platforms.sort((a, b) => {
        const indexA = PlatformOrderList.findIndex(type => a.platform === type);
        const indexB = PlatformOrderList.findIndex(type => b.platform === type);
        return indexA - indexB; // to get positive, 0, negative number for the sort callback.
      });
      if (self.WorkspaceStore?.current_workspace?.primary_platform) {
        const index = platformsSorted.findIndex(p => p.platform === self.WorkspaceStore.current_workspace.primary_platform);
        platformsSorted.unshift(platformsSorted.splice(index, 1)[0]);
      }
      return platformsSorted
    }
  }))
  .actions(self => ({
    afterCreate() {
      self.fetchCampaign();
    },
    fetchCampaign: flow(function* () {
	  self.isLoading = true;
      try {
        const {data} = yield request.get(self.baseURL);
		
		self.campaign = null;
        self.campaign = data;
      } catch (err) {
        console.error(err);
      } finally {
        self.isLoading = false;
      }
    }),
	updateCampaign: flow(function* () {
		try {
		  	yield request.put(self.baseURL, getSnapshot(self.campaign));
		} catch (err) {
			console.error(err);
		} finally {
		}
	}),
    deleteCampaign: flow(function* () {
      const url = `${self.baseURL}`;
      try {
        const confirmDelete = yield confirm({
          text: 'Are you sure you want to delete this campaign? You cannot undo these changes',
          okText: 'Delete'
        });
        if (!confirmDelete) return;

        yield request.delete(url);
        message({type: 'success', text: 'Successfully deleted campaign.'});
        destroy(self);
      } catch (err) {
        throw err;
      } finally {
      }
    }),
	addCampaignPlatform: flow(function* (name) {
	  if(self.campaign.campaign_platforms.find(p => p.platform === name) || (self.campaign.campaign_pinterest && name === 'PINTEREST') || (self.campaign.campaign_social && name === 'SOCIAL')){
		return;
	  }
	  const body = { platform: name, due_date: self.campaign.sorted_due_dates[1], workspace_id: self.WorkspaceStore.current_workspace.id, idea_id: self.campaign.idea.id };
	  try {
		yield request.post(self.baseURL, body);
		self.fetchCampaign();
	  } catch (err) {
		console.error(err);
	  } finally {
	  }
	}),
    hideLinkIdeasModal() {
      self.visibleLinkIdeasModal = false;
    },
    openLinkIdeasModal() {
      self.visibleLinkIdeasModal = true;
    }
  }));

export default CampaignsModel;
