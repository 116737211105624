import React, { Component } from 'react';
import SVG from 'components/svg/svg';

import classNames from 'classnames';
import { Observer } from 'mobx-react-lite';

export default class Checkbox extends Component {
	render() {
		const { className, label, onChange, checked, size, titleIcon } = this.props;

		return (<Observer>{() => (
			<div className={classNames('tejik-checkbox', size ? `tejik-checkbox-${size}` : '', className)}>
				<label>
					<input type="checkbox" id={label} checked={checked} onChange={onChange} />
					<span class="circular-checkbox"></span>
					<span>{label}</span>
				</label>
				{titleIcon && <SVG name={titleIcon} size={18} className='tejik-checkbox-icon'/>}
			</div>
		)}</Observer>
		);
	}
}