import request from 'api/request';
import { types, flow, getSnapshot } from 'mobx-state-tree';
import { Modal } from 'components';
import debounce from 'lodash.debounce';

const { message } = Modal;
const { enumeration, identifier, string, model } = types;

export const OrganizationModel = model('OrganizationModel', {
	id: identifier,
	name: string,
	payment_status: enumeration('PaymentStatusEnum', ['PAID', 'UNPAID', 'NOT_SETUP']),
	role: enumeration('OrganizationUserRole', ['ADMIN', 'GUEST', 'MEMBER', 'OWNER']),
}).views((self) => ({
	get baseURL() {
		return `/organizations/${self.id}`;
	},
})).actions((self) => ({
	saveOrganization: debounce(() => self.updateOrganization(), 700),
	updateOrganization: flow(function* () {
		try {
			yield request.put(self.baseURL, {
				id: self.id,
				name: self.name,
			});
			message({ type: 'success', text: 'Successfully updated organization.' });
		} catch (err) {
			throw err;
		} finally {
		}
	}),
	setName(name) {
		self.name = name;
		self.saveOrganization();
	}
}));
