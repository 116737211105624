import { observer } from 'mobx-react-lite';
import { useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { ModelConnector, rootStore } from 'stores';
import { Button, Card, Container, Form, Info, Input, PlatformSelection, Select, UserImage } from 'components';
import { PlatformSelectionList } from 'utils/utils';
import WorkspaceViewModel from './workspace.model';

import classNames from 'classnames/bind';
import styles from './workspace.scss';
import SVG from 'components/svg/svg';

const cx = classNames.bind(styles);

const WorkspaceForm = observer(({ model }) => {
	if (model.isLoading) return 'Loading';

	return (
		<>
			<Form>
				<Form.Item label="Workspace Name">
					<Input value={model.workspace.name} placeholder="Name your workspace" onChange={(e) => model.workspace.setName(e.target.value)} />
				</Form.Item>
				<Form.Item label="Platforms">
					<PlatformSelection
						platforms={model.workspace.platforms}
						togglePlatform={model.workspace.togglePlatform}
						workspacePlatforms={PlatformSelectionList.map(({ value }) => value)}
					/>
				</Form.Item>
				<Form.Item label="Domain">
					<Input value={model.workspace.domain || ''} preffix={'https://'} placeholder="sitename.com" onChange={(e) => model.workspace.setDomain(e.target.value)} className={'workspace-domain-imput'} />
				</Form.Item>
				<Form.Item label="Pillar Content Platform">
					<Select value={model.workspace.primary_platform} options={model.pillarPlatformOptions} onChange={model.workspace.setPrimaryPlatform} width={300} type='checked-select' />
				</Form.Item>
				<Form.Item label="Users">
					<div className={cx('workspace-users-list', 'tejik-selection')}>
						{model.users.map((user) => (
							<Button
								key={user.id}
								className={cx('workspace-list-button', 'tejik-selection-button', { 'active': model.workspace.hasUser(user.id) })}
								type="text"
								onClick={() => model.workspace.toggleUser(user.id, user)}
							>
								<UserImage user={user} size={32} />
							</Button>
						))}
					</div>
				</Form.Item>
			</Form>
			{model.shouldConfigure &&
				<Container noPadding className={cx('workspace-additional-settings')}>
					<Container.Column span={4}>
						<Card action="edit" icon="calendar_month" text="Scheduling Targets" to="scheduling-targets" />
					</Container.Column>
					<Container.Column span={4}>
						<Card action="edit" icon="dataset" text="Campaign Statuses" to="campaign-statuses" />
					</Container.Column>
					<Container.Column span={4}>
						<Card action="edit" icon="task" text="Tasks" to="tasks" />
					</Container.Column>
				</Container>
			}
			{!model.shouldUpdate && (
				<div className={cx('workspace-save')}>
					<Button onClick={model.saveWorkspace} size="large">Save</Button>
				</div>
			)}
			<div className={classNames('show-mobile-only')} style={{ height: 25 }}></div>
		</>
	);
});

const Workspace = ModelConnector(WorkspaceForm, WorkspaceViewModel)

export { Workspace };

const WorkspaceView = ({ allowClose = true, onOk, shouldConfigure }) => {
	const { organization_id } = useParams();

	useEffect(() => {
		rootStore.WorkspaceStore.hideNewIdeaButton();
	}, []);

	return (
		<Container title="Workspace Settings" titleSize={1} titleIcon={'workspaces'} titleWeight={400} actions={allowClose && <Link to={`/organization-settings/${organization_id}/workspaces`}><SVG name="close" /></Link>}>
			<Workspace onOk={onOk} shouldConfigure={shouldConfigure} />
			<Info infoKey="workspace-settings" />
		</Container>
	);
};

export default WorkspaceView;
