import request from 'api/request';
import { Modal } from 'components';
import debounce from 'lodash.debounce';
import { types, flow, getSnapshot } from 'mobx-state-tree';
import { PlatformEnum } from 'models/global/global.model';
import { rootStore } from 'stores';

const { message } = Modal;
const { array, identifier, string, maybeNull, model } = types;
export const IdeaModel = model('IdeaModel', {
	id: identifier,
	title: string,
	platforms: array(PlatformEnum),
	sponsored_platforms: array(PlatformEnum),
	notes: maybeNull(string),

	campaign: maybeNull(model('CampaignIdModel', {
		id: maybeNull(string),
	})),

	linked_ideas: array(model('LinkedIdeaModel', {
		id: identifier,
		title: string,
	})),
	updated_at: maybeNull(string),
	starred_at: maybeNull(string),
})
	.views((self) => ({
		get baseURL() {
			return `${rootStore.WorkspaceStore.currentBaseURL}/ideas/${self.id}`;
		},
		hasPlatform(value) {
			return self.platforms.includes(value);
		},
		hasSponsoredPlatform(value) {
			return self.sponsored_platforms.includes(value);
		},
	}))
	.actions((self) => ({
		addNewLinkedIdea: flow(function* (idea) {
			if (self.id === 'new') {
				self.linked_ideas.push(idea);
				return;
			}

			try {
				const { data } = yield request.post(`${self.baseURL}/link`, {
					linked_idea_id: idea.id,
				});
	
				self.linked_ideas.push(data);
				message({ type: 'success', text: 'Successfully linked ideas.'})
			} catch (err) {
				console.error(err);
			}
		}),
		removeLinkedIdea: flow(function* (idea) {
			if (self.id === 'new') {
				self.linked_ideas = self.linked_ideas.filter(li => li.id !== idea.id);
				return;
			}

			try {
				yield request.post(`${self.baseURL}/unlink`, {
					linked_idea_id: idea.id,
				});
	
				self.linked_ideas = self.linked_ideas.filter(li => li.id !== idea.id);
				message({ type: 'success', text: 'Successfully unlinked ideas.'})
			} catch (err) {
				console.error(err);
			}
		}),
		saveIdea: debounce(() => self.updateIdea(), 700),
		updateIdea: flow(function* () {
			if (self.id === 'new') return;
			try {
				yield request.put(self.baseURL, getSnapshot(self));
				message({ type: 'success', text: 'Successfully updated idea.' });
			} catch (err) {
				throw err;
			} finally {
			}
		}),
		starIdea: flow(function* () {
			yield request.post(`${self.baseURL}/star`, { starred_at: self.starred_at });
		}),
		// Setters
		setTitle(title) {
			self.title = title;
			self.saveIdea();
		},
		setNotes(notes) {
			self.notes = notes;
			self.saveIdea();
		},
		setStarredAt() {
			if (self.starred_at) {
				self.starred_at = null;
			} else {
				self.starred_at = new Date().toISOString();
			}
			self.starIdea(false);
		},
		
		// Platform
		addPlatform(value) {
			self.platforms.push(value);
		},
		removePlatform(value) {
			const index = self.platforms.findIndex((platform) => platform === value);
			self.platforms.splice(index, 1);
		},
		togglePlatform(value) {
			if (self.hasPlatform(value)) {
				self.removePlatform(value);
			} else {
				self.addPlatform(value);
			}
			self.saveIdea();
		},

		// Sponsored Platform
		addSponsoredPlatform(value) {
			self.sponsored_platforms.push(value);
		},
		removeSponsoredPlatform(value) {
			const index = self.sponsored_platforms.findIndex((platform) => platform === value);
			self.sponsored_platforms.splice(index, 1);
		},
		toggleSponsoredPlatform(value) {
			if (self.hasSponsoredPlatform(value)) {
				self.removeSponsoredPlatform(value);
			} else {
				self.addSponsoredPlatform(value);
			}
			self.saveIdea();
		},
	}));
