import classNames from 'classnames';

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useRef, useState } from "react";
import {
	CAN_REDO_COMMAND,
	CAN_UNDO_COMMAND,
	REDO_COMMAND,
	UNDO_COMMAND,
	SELECTION_CHANGE_COMMAND,
	FORMAT_TEXT_COMMAND,
	$getSelection,
	$isRangeSelection,
	$createParagraphNode,
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
	$wrapNodes,
	$isAtNodeEnd
} from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
	INSERT_ORDERED_LIST_COMMAND,
	INSERT_UNORDERED_LIST_COMMAND,
	REMOVE_LIST_COMMAND,
	$isListNode,
	ListNode
} from "@lexical/list";
import { createPortal } from "react-dom";
import { $isHeadingNode } from "@lexical/rich-text";
import SVG from "components/svg/svg";

const LowPriority = 1;

function Divider() {
	return <div className="divider" />;
}

function positionEditorElement(editor, rect) {
	if (rect === null) {
		editor.style.opacity = "0";
		editor.style.top = "-1000px";
		editor.style.left = "-1000px";
	} else {
		editor.style.opacity = "1";
		editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
		editor.style.left = `${rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2
			}px`;
	}
}

function FloatingLinkEditor({ editor }) {
	const editorRef = useRef(null);
	const inputRef = useRef(null);
	const mouseDownRef = useRef(false);
	const [linkUrl, setLinkUrl] = useState("");
	const [isEditMode, setEditMode] = useState(false);
	const [lastSelection, setLastSelection] = useState(null);

	const updateLinkEditor = useCallback(() => {
		const selection = $getSelection();
		if ($isRangeSelection(selection)) {
			const node = getSelectedNode(selection);
			const parent = node.getParent();
			if ($isLinkNode(parent)) {
				setLinkUrl(parent.getURL());
			} else if ($isLinkNode(node)) {
				setLinkUrl(node.getURL());
			} else {
				setLinkUrl("");
			}
		}
		const editorElem = editorRef.current;
		const nativeSelection = window.getSelection();
		const activeElement = document.activeElement;

		if (editorElem === null) {
			return;
		}

		const rootElement = editor.getRootElement();
		if (
			selection !== null &&
			!nativeSelection.isCollapsed &&
			rootElement !== null &&
			rootElement.contains(nativeSelection.anchorNode)
		) {
			const domRange = nativeSelection.getRangeAt(0);
			let rect;
			if (nativeSelection.anchorNode === rootElement) {
				let inner = rootElement;
				while (inner.firstElementChild != null) {
					inner = inner.firstElementChild;
				}
				rect = inner.getBoundingClientRect();
			} else {
				rect = domRange.getBoundingClientRect();
			}

			if (!mouseDownRef.current) {
				positionEditorElement(editorElem, rect);
			}
			setLastSelection(selection);
		} else if (!activeElement || activeElement.className !== "link-input") {
			positionEditorElement(editorElem, null);
			setLastSelection(null);
			setEditMode(false);
			setLinkUrl("");
		}

		return true;
	}, [editor]);

	useEffect(() => {
		return mergeRegister(
			editor.registerUpdateListener(({ editorState }) => {
				editorState.read(() => {
					updateLinkEditor();
				});
			}),

			editor.registerCommand(
				SELECTION_CHANGE_COMMAND,
				() => {
					updateLinkEditor();
					return true;
				},
				LowPriority
			)
		);
	}, [editor, updateLinkEditor]);

	useEffect(() => {
		editor.getEditorState().read(() => {
			updateLinkEditor();
		});
	}, [editor, updateLinkEditor]);

	useEffect(() => {
		if (isEditMode && inputRef.current) {
			inputRef.current.focus();
		}
	}, [isEditMode]);

	return (
		<div ref={editorRef} className="link-editor">
			{isEditMode ? (
				<input
					ref={inputRef}
					className="link-input"
					value={linkUrl}
					onChange={(event) => {
						setLinkUrl(event.target.value);
					}}
					onKeyDown={(event) => {
						if (event.key === "Enter") {
							event.preventDefault();
							if (lastSelection !== null) {
								if (linkUrl !== "") {
									editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
								}
								setEditMode(false);
							}
						} else if (event.key === "Escape") {
							event.preventDefault();
							setEditMode(false);
						}
					}}
				/>
			) : (
				<>
					<div className="link-input">
						<a href={ linkUrl} target="_blank">
							{linkUrl}
						</a>
						<button
							className="link-edit"
							tabIndex={0}
							type="button"
							onMouseDown={(event) => event.preventDefault()}
							onClick={() => {
								setEditMode(true);
							}}
						>
							<SVG name="edit"/>
						</button>
					</div>
				</>
			)}
		</div>
	);
}

function getSelectedNode(selection) {
	const anchor = selection.anchor;
	const focus = selection.focus;
	const anchorNode = selection.anchor.getNode();
	const focusNode = selection.focus.getNode();
	if (anchorNode === focusNode) {
		return anchorNode;
	}
	const isBackward = selection.isBackward();
	if (isBackward) {
		return $isAtNodeEnd(focus) ? anchorNode : focusNode;
	} else {
		return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
	}
}

export default function ToolbarPlugin() {
	const [editor] = useLexicalComposerContext();
	const toolbarRef = useRef(null);
	const [canUndo, setCanUndo] = useState(false);
	const [canRedo, setCanRedo] = useState(false);
	const [blockType, setBlockType] = useState("paragraph");
	const [isLink, setIsLink] = useState(false);
	const [isBold, setIsBold] = useState(false);
	const [isItalic, setIsItalic] = useState(false);
	const [isUnderline, setIsUnderline] = useState(false);
	const [isStrikethrough, setIsStrikethrough] = useState(false);

	const updateToolbar = useCallback(() => {
		const selection = $getSelection();
		if ($isRangeSelection(selection)) {
			const anchorNode = selection.anchor.getNode();
			const element =
				anchorNode.getKey() === "root"
					? anchorNode
					: anchorNode.getTopLevelElementOrThrow();
			const elementKey = element.getKey();
			const elementDOM = editor.getElementByKey(elementKey);
			if (elementDOM !== null) {
				if ($isListNode(element)) {
					const parentList = $getNearestNodeOfType(anchorNode, ListNode);
					const type = parentList ? parentList.getTag() : element.getTag();
					setBlockType(type);
				} else {
					const type = $isHeadingNode(element)
						? element.getTag()
						: element.getType();
					setBlockType(type);
				}
			}
			// Update text format
			setIsBold(selection.hasFormat("bold"));
			setIsItalic(selection.hasFormat("italic"));
			setIsUnderline(selection.hasFormat("underline"));
			setIsStrikethrough(selection.hasFormat("strikethrough"));

			// Update links
			const node = getSelectedNode(selection);
			const parent = node.getParent();
			if ($isLinkNode(parent) || $isLinkNode(node)) {
				setIsLink(true);
			} else {
				setIsLink(false);
			}
		}
	}, [editor]);

	useEffect(() => {
		return mergeRegister(
			editor.registerUpdateListener(({ editorState }) => {
				editorState.read(() => {
					updateToolbar();
				});
			}),
			editor.registerCommand(
				SELECTION_CHANGE_COMMAND,
				(_payload, newEditor) => {
					updateToolbar();
					return false;
				},
				LowPriority
			),
			editor.registerCommand(
				CAN_UNDO_COMMAND,
				(payload) => {
					setCanUndo(payload);
					return false;
				},
				LowPriority
			),
			editor.registerCommand(
				CAN_REDO_COMMAND,
				(payload) => {
					setCanRedo(payload);
					return false;
				},
				LowPriority
			)
		);
	}, [editor, updateToolbar]);

	const insertLink = useCallback(() => {
		if (!isLink) {
			editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
		} else {
			editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
		}
	}, [editor, isLink]);

	return (
		<div className="toolbar" ref={toolbarRef}>
			<button
				disabled={!canUndo}
				onClick={() => {
					editor.dispatchCommand(UNDO_COMMAND);
				}}
				className="toolbar-item spaced"
				aria-label="Undo"
				type="button"
			>
				<SVG name="undo" />
			</button>
			<button
				disabled={!canRedo}
				onClick={() => {
					editor.dispatchCommand(REDO_COMMAND);
				}}
				className="toolbar-item"
				aria-label="Redo"
				type="button"
			>
				<SVG name="redo" />
			</button>
			<Divider />
			<button
				className={classNames("toolbar-item", { 'active': blockType === 'paragraph' })}
				onClick={() => {
					editor.update(() => {
						const selection = $getSelection();

						if ($isRangeSelection(selection)) {
							$wrapNodes(selection, () => $createParagraphNode());
						}
					})
				}}
				type="button"
			>
				<SVG name="view_headline" className="mr-05" size={18}/>
				<span className="text">Normal</span>
				{blockType === "paragraph" && <span className="active" />}
			</button>
			<button
				className={classNames("toolbar-item", { 'active': blockType === 'ul' })}
				onClick={blockType === "ul" ? () => editor.dispatchCommand(REMOVE_LIST_COMMAND) : () => editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND)}
				type="button"
			>
				<SVG name="format_list_bulleted" className="mr-05" size={18}/>
				<span className="text">Bullet List</span>
			</button>
			<button
				className={classNames("toolbar-item", { 'active': blockType === 'ol' })}
				onClick={blockType === "ol" ? () => editor.dispatchCommand(REMOVE_LIST_COMMAND) : () => editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND)}
				type="button"
			>
				<SVG name="format_list_numbered" className="mr-05" size={18}/>
				<span className="text">Numbered List</span>
			</button>
			<button
				onClick={() => {
					editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
				}}
				className={"toolbar-item spaced " + (isBold ? "active" : "")}
				aria-label="Format Bold"
				type="button"
			>
				<SVG name="format_bold" />
			</button>
			<button
				onClick={() => {
					editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
				}}
				className={"toolbar-item spaced " + (isItalic ? "active" : "")}
				aria-label="Format Italics"
				type="button"
			>
				<SVG name="format_italic" />
			</button>
			<button
				onClick={() => {
					editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
				}}
				className={"toolbar-item spaced " + (isUnderline ? "active" : "")}
				aria-label="Format Underline"
				type="button"
			>
				<SVG name="format_underlined" />
			</button>
			<button
				onClick={() => {
					editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
				}}
				className={
					"toolbar-item spaced " + (isStrikethrough ? "active" : "")
				}
				aria-label="Format Strikethrough"
				type="button"
			>
				<SVG name="format_strikethrough" />
			</button>
			<button
				onClick={insertLink}
				className={"toolbar-item spaced " + (isLink ? "active" : "")}
				aria-label="Insert Link"
				type="button"
			>
				<SVG name="link" />
			</button>
			{isLink &&
				createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
		</div>
	);
}
