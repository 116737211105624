import request from 'api/request';
import debounce from 'lodash.debounce';

import { destroy, flow, getParent, getSnapshot, types } from 'mobx-state-tree';
import { IdeaModel } from './idea.model';
import { PlatformEnum } from './global/global.model';
import { StatusModel } from './status.model';
import { KeywordResearchModel } from './keyword-research.model';
import { DateFormatter } from 'utils/utils';
import { TaskModel } from 'views/app/workspace-settings/tasks/tasks.model';
import { Modal } from 'components';
import { rootStore } from 'stores';

import { CategoryModel } from 'views/app/workspace-settings/social/categories/categories.model';
import { ContentTypeModel } from 'views/app/workspace-settings/social/social.model';
import { HookModel } from 'views/app/workspace-settings/social/hooks/hooks.model';
import { CTAModel } from 'views/app/workspace-settings/social/call-to-action/call-to-action.model';
import { startOfDay } from 'date-fns';

const { confirm, message } = Modal;
const {
  array,
  boolean,
  compose,
  enumeration,
  identifier,
  string,
  number,
  maybeNull,
  model,
  safeReference,
  union
} = types;

const CampaignPlatformDataMap = new Map([
  [
    'BLOG',
    model('BlogDataModel', {
      title: maybeNull(string),
      description: maybeNull(string)
    })
      .views(self => ({
        get parent() {
          return getParent(self, 1);
        },
        get url() {
          return self.parent?.campaign?.url_slug;
        },
        get setURL() {
          return self.parent?.campaign?.setUrlSlug;
        }
      }))
      .actions(self => ({
        setTitle(title) {
          if (title.length > self.parent.character_limit?.title) {
            message({
              type: 'error',
              text: 'You have reached the maximum character limit for this field.'
            });
            return;
          }
          self.title = title;
          self.parent.saveData();
        },
        setDescription(description) {
          if (description.length > self.parent.character_limit?.description) {
            message({
              type: 'error',
              text: 'You have reached the maximum character limit for this field.'
            });
            return;
          }
          self.description = description;
          self.parent.saveData();
        }
      }))
  ],
  [
    'PODCAST',
    model('PodcastDataModel', {
      episode_number: maybeNull(number),
      title: maybeNull(string),
      description: maybeNull(string)
    })
      .views(self => ({
        get parent() {
          return getParent(self, 1);
        }
      }))
      .actions(self => ({
        setEpisodeNumber(episode_number) {
          self.episode_number = Number(episode_number);
          self.parent.saveData();
        },
        setTitle(title) {
          if (title.length > self.parent.character_limit?.title) {
            message({
              type: 'error',
              text: 'You have reached the maxium character limit for this field.'
            });
            return;
          }
          self.title = title;
          self.parent.saveData();
        },
        setDescription(description) {
          if (description.length > self.parent.character_limit?.description) {
            message({
              type: 'error',
              text: 'You have reached the maxium character limit for this field.'
            });
            return;
          }
          self.description = description;
          self.parent.saveData();
        }
      }))
  ],
  [
    'YOUTUBE',
    model('YoutubeDataModel', {
      title: maybeNull(string),
      description: maybeNull(string)
    })
      .views(self => ({
        get parent() {
          return getParent(self, 1);
        }
      }))
      .actions(self => ({
        setTitle(title) {
          if (title.length > self.parent.character_limit?.title) {
            message({
              type: 'error',
              text: 'You have reached the maxium character limit for this field.'
            });
            return;
          }
          self.title = title;
          self.parent.saveData();
        },
        setDescription(description) {
          if (description.length > self.parent.character_limit?.description) {
            message({
              type: 'error',
              text: 'You have reached the maxium character limit for this field.'
            });
            return;
          }
          self.description = description;
          self.parent.saveData();
        }
      }))
  ],
  [
    'EMAIL',
    model('EmailDataModel', {
      subject: maybeNull(string),
      content: maybeNull(string)
    })
      .views(self => ({
        get parent() {
          return getParent(self, 1);
        }
      }))
      .actions(self => ({
        setSubject(subject) {
          if (subject.length > self.parent.character_limit?.title) {
            message({
              type: 'error',
              text: 'You have reached the maxium character limit for this field.'
            });
            return;
          }
          self.subject = subject;
          self.parent.saveData();
        },
        setContent(content) {
          self.content = content;
          self.parent.saveData();
        }
      }))
  ]
]);

export const CampaignContentLink = model('CampaignContentLinkModel', {
  id: identifier,
  url: string,
  content_link_id: string,
  campaign_platform_id: maybeNull(string),
  pinterest_pin_id: maybeNull(string),
  social_post_id: maybeNull(string)
})
  .views(self => ({
    get baseURL() {
      return `${rootStore.WorkspaceStore.current_workspace.baseURL}/campaign-content-links/${self.id}`;
    }
  }))
  .actions(self => ({
    updateCampaignContentLink: flow(function* () {
      try {
        yield request.put(self.baseURL, getSnapshot(self));
        message({
          type: 'success',
          text: 'Successfully updated content link url.'
        });
      } catch (err) {
      } finally {
      }
    }),
    setUrl(url) {
      self.url = url;
    }
  }));

export const CampaignTaskModel = model('CampaignTaskModel', {
  id: identifier,
  task: TaskModel,
  completed: boolean
})
  .views(self => ({
    get baseURL() {
      return `${self.parent.baseURL}/campaign-tasks/${self.id}`;
    },
    get parent() {
      return getParent(self, 2);
    }
  }))
  .actions(self => ({
    saveCampaignTask: debounce(() => self.updateCampaignTask(), 700),
    updateCampaignTask: flow(function* () {
      try {
        yield request.put(self.baseURL, getSnapshot(self));
        message({
          type: 'success',
          text: 'Successfully marked task complete.'
        });
      } catch (err) {
        throw err;
      } finally {
      }
    }),
    setCompleted(completed) {
      self.completed = completed;
      self.saveCampaignTask();
    }
  }));

export const CampaignPlatformModel = model('CampaignPlatformModel', {
  id: identifier,
  keyword_research: maybeNull(KeywordResearchModel),
  platform: PlatformEnum,
  due_date: string,
  status: maybeNull(StatusModel),
  sponsored_content: boolean,
  campaign_content_links: array(CampaignContentLink),
  campaign_tasks: array(CampaignTaskModel),
  recycle_at: maybeNull(string)
})
  .views(self => ({
    get campaign() {
      return getParent(self, 2);
    },
    get baseURL() {
      return `${self.campaign.baseURL}/campaign-platform/${self.id}`;
    },
    get sorted_campaign_tasks() {
      return Array.from(self.campaign_tasks).sort(
        (a, b) => a.task.order - b.task.order
      );
    },
    get percentage_complete() {
      const allStatusus =
        rootStore.WorkspaceStore.current_workspace.statusOptions([
          self.platform
        ]);
      const statusIndex = allStatusus.findIndex(
        status => status?.id === self.status?.id
      );

      const totalStatusCount = allStatusus.length - 1;
      if (statusIndex === -1) return 0;
      return Math.round((statusIndex / totalStatusCount) * 100);
    },
    get character_limit() {
      return rootStore.WorkspaceStore.current_workspace.getCharacterLimit(
        self.platform
      );
    },
    get formated_due_date() {
      return DateFormatter(self.due_date, 'MD');
    }
  }))
  .actions(self => ({
    setStatus: flow(function* (status_id) {
      try {
        const { data } = yield request.put(`${self.baseURL}/status`, {
          status_id
        });
        message({ type: 'success', text: 'Successfully changed status.' });
        self.status = data;
      } catch (err) {
        throw err;
      } finally {
      }
    }),
    addCampaignContentLink: flow(function* ({ content_link_id, url }) {
      try {
        const { data } = yield request.post(`${self.baseURL}/content-link`, {
          content_link_id,
          url
        });
        self.campaign_content_links.push(data);
        message({
          type: 'success',
          text: 'Successfully added content link url.'
        });
      } catch (err) {
        throw err;
      } finally {
      }
    }),
    deletePlatform: flow(function* (platform_id) {
      const url = `${self.baseURL}`;
      try {
        const confirmDelete = yield confirm({
          text: 'Are you sure you want to delete this platform? You cannot undo these changes',
          okText: 'Delete'
        });
        if (!confirmDelete) return;

        yield request.delete(url);
        message({ type: 'success', text: 'Platform successfully deleted.' });
        window.location.reload(true);
        const platform = self; //self.model.find(post => post.id === platform_id);
        destroy(platform);
      } catch (err) {
        throw err;
      } finally {
      }
    }),
    setDueDate(due_date) {
      self.due_date = startOfDay(new Date(due_date)).toISOString();
      self.saveData();
    }
  }));

const SocialPlatformDataModel = model('SocialPlatformDataModel', {
  hashtag_group_id: maybeNull(string),
  caption: maybeNull(string),
  title: maybeNull(string)
}).views(self => ({
  hashtag_string(platform) {
    const current_workspace = rootStore.WorkspaceStore.current_workspace;
    const foundPlatformCount = current_workspace.platform_hashtag_counts.find(
      platform_hashtag_count => platform_hashtag_count.platform === platform
    );
    const selectedGroup = current_workspace.hashtag_groups.find(
      group => group.id === self.hashtag_group_id
    );

    const randomHashtags = [];
    if (foundPlatformCount?.count) {
      // Select random hashtags
      const hashtagOptions = [...(selectedGroup?.hashtags || [])];
      const count = foundPlatformCount?.count;

      for (let i = 0; i < count; i++) {
        const randomIndex = Math.floor(Math.random() * hashtagOptions.length);
        const hashtag = hashtagOptions.splice(randomIndex, 1);
        randomHashtags.push(hashtag.at(0));
      }
    }
    const hashtags =
      randomHashtags.length > 0
        ? [...randomHashtags]
        : selectedGroup?.hashtags || [];

    return hashtags.map(hashtag => hashtag?.tag).join(' ');
  }
}));

const SocialPostDataModel = model('SocialPostDataModel', {
  custom_hook: maybeNull(string),
  design: maybeNull(string),
  steps: maybeNull(string),
  INSTAGRAM: maybeNull(SocialPlatformDataModel),
  FACEBOOK: maybeNull(SocialPlatformDataModel),
  YOUTUBE: maybeNull(SocialPlatformDataModel),
  TIKTOK: maybeNull(SocialPlatformDataModel),
  LINKEDIN: maybeNull(SocialPlatformDataModel),
  TWITTER: maybeNull(SocialPlatformDataModel),
  PINTEREST: maybeNull(SocialPlatformDataModel)
});

export const GenerateCaptionText = (
  platform,
  socialHook,
  transformation,
  callToAction,
  hashtagString
) => {
  const current_workspace = rootStore.WorkspaceStore.current_workspace;
  let text = ``;
  if (socialHook) text += socialHook;
  if (
    current_workspace.social_caption?.transformation_platforms.includes(
      platform
    ) &&
    transformation
  )
    text += '\n\n' + transformation;
  if (
    current_workspace.social_caption?.call_to_action_platforms.includes(
      platform
    ) &&
    callToAction
  )
    text += '\n\n' + callToAction;
  if (hashtagString) {
    text += `\n.\n.\n.\n` + hashtagString;
  }
  return text;
};

export const SocialPostModel = model('SocialPostModel', {
  id: identifier,
  data: maybeNull(SocialPostDataModel),
  due_date: string,
  title: maybeNull(string),
  platforms: array(PlatformEnum),
  is_active: maybeNull(boolean),

  category: maybeNull(CategoryModel),
  content_type: maybeNull(ContentTypeModel),
  hook: maybeNull(HookModel),
  call_to_action: maybeNull(CTAModel),
  campaign_content_links: array(CampaignContentLink)
})
  .views(self => ({
    get parent() {
      return getParent(self, 2);
    },
    get baseURL() {
      return `${self.parent.baseURL}/social-post/${self.id}`;
    },
    get formated_due_date() {
      return DateFormatter(self.due_date, 'MD');
    }
  }))
  .actions(self => ({
    updateCaptions(platforms, socialHook, transformation, callToAction) {
      for (const platform of platforms) {
        const caption = self.generateCaptionText(
          platform,
          socialHook,
          transformation,
          callToAction
        );
        self.setData(platform, caption);
      }
    },
    saveSocialPost: debounce(() => self.parent.updateSocialPost(self), 700),
    setProperty(key, value) {
      if (key in self) {
        self[key] = value;
      }
    },
    setData(key, value) {
      if (key in self.data) {
        self.data = {
          ...self.data,
          [key]:
            typeof value === 'string' ? value : { ...self.data[key], ...value }
        };
      }
      self.saveSocialPost();
    },
    selectHashtagGroup: flow(function* (dataKey, value) {
      const confirmUpdate = yield confirm({
        text: 'Are you sure you want to edit this hashtag group?',
        children:
          "If you continue, the social media caption will be regenerated based on it's current settings.",
        okText: 'Generate'
      });
      if (!confirmUpdate) return;

      self.setData(dataKey, { hashtag_group_id: value });

      const newText = GenerateCaptionText(
        dataKey,
        self.data.custom_hook || self.hook?.name,
        self.data?.steps,
        self.call_to_action?.name,
        self.data[dataKey].hashtag_string(dataKey)
      );

      self.setData(dataKey, { caption: newText });
    }),
    addCampaignContentLink: flow(function* ({ content_link_id, url }) {
      try {
        const { data } = yield request.post(`${self.baseURL}/content-link`, {
          content_link_id,
          url
        });
        self.campaign_content_links.push(data);
        message({
          type: 'success',
          text: 'Successfully added content link url.'
        });
      } catch (err) {
        throw err;
      } finally {
      }
    })
  }));

export const CampaignSocialModel = model('CampaignSocialModel', {
  id: identifier,
  platforms: array(PlatformEnum),
  status: maybeNull(StatusModel),
  social_posts: array(SocialPostModel),
  selected_social_post: safeReference(SocialPostModel),
  recycle_at: maybeNull(string)
})
  .views(self => ({
    get parent() {
      return getParent(self, 1);
    },
    get baseURL() {
      return `${self.parent.baseURL}/campaign-social/${self.id}`;
    },
    get sorted_due_dates() {
      const due_dates = [...self.social_posts].sort(
        (a, b) => new Date(a.due_date) - new Date(b.due_date)
      );
      return due_dates;
    },
    get due_date() {
      return self.sorted_due_dates[0]?.due_date;
    },
    get formated_due_date() {
      return DateFormatter(self.due_date, 'MD');
    },
    get percentage_complete() {
      const allStatusus =
        rootStore.WorkspaceStore.current_workspace.statusOptions(
          self.platforms
        );
      const statusIndex = allStatusus.findIndex(
        status => status?.id === self.status?.id
      );

      const totalStatusCount = allStatusus.length - 1;
      if (statusIndex === -1) return 0;
      return Math.round((statusIndex / totalStatusCount) * 100);
    }
  }))
  .actions(self => ({
    updateDueDates: flow(function* (due_date) {
      const confirmUpdate = yield confirm({
        text: 'Are you sure you want to update the base due date?',
        children:
          'If you continue, all current social dates will be changed as well.',
        okText: 'Continue'
      });
      if (!confirmUpdate) return;

      const { data } = yield request.put(`${self.baseURL}/dates`, { due_date });
      message({ type: 'success', text: 'Successfully updated dates.' });
      self.social_posts = data;
    }),
    setStatus: flow(function* (status_id) {
      try {
        const { data } = yield request.put(`${self.baseURL}/status`, {
          status_id
        });
        message({ type: 'success', text: 'Successfully changed status.' });
        self.status = data;
      } catch (err) {
        throw err;
      } finally {
      }
    }),
    addSocialPost: flow(function* (reqData) {
      try {
        const { data } = yield request.post(self.baseURL, reqData);
        message({ type: 'success', text: 'Successfully added social post.' });
        self.social_posts.push(data);
      } catch (err) {
        throw err;
      } finally {
      }
    }),
    updateSocialPost: flow(function* (reqData) {
      const social_post_id = reqData.id || self.selected_social_post.id;
      const url = `${self.baseURL}/social-post/${social_post_id}`;

      try {
        const { data } = yield request.put(url, reqData);
        message({ type: 'success', text: 'Successfully updated social post.' });
        for (const [key, value] of Object.entries(data)) {
          if (!value || !self.selected_social_post) continue;
          self.selected_social_post.setProperty(key, value);
        }
      } catch (err) {
        throw err;
      } finally {
      }
    }),
    deleteSocialPost: flow(function* (social_post_id) {
      const url = `${self.baseURL}/social-post/${social_post_id}`;
      try {
        const confirmDelete = yield confirm({
          text: 'Are you sure you want to delete this social post? You cannot undo these changes',
          okText: 'Delete'
        });
        if (!confirmDelete) return;

        yield request.delete(url);
        message({ type: 'success', text: 'Successfully deleted social post.' });
        const social_post = self.social_posts.find(
          post => post.id === social_post_id
        );
        destroy(social_post);
      } catch (err) {
        throw err;
      } finally {
      }
    }),
    setSelectedPost(id) {
      self.selected_social_post = id;
    }
  }));

const PinterestContentType = maybeNull(
  enumeration('PinterestContentType', ['IDEA', 'IMAGE', 'STATIC', 'VIDEO'])
);

export const PinterestPinModel = model('PinterestPinModel', {
  id: identifier,
  title: maybeNull(string),
  description: maybeNull(string),
  due_date: maybeNull(string),
  is_configured: boolean,
  pinterest_content_type: PinterestContentType,
  campaign_content_links: array(CampaignContentLink)
})
  .views(self => ({
    get parent() {
      return getParent(self, 2);
    },
    get baseURL() {
      return `${self.parent.baseURL}/pin/${self.id}`;
    },
    get formated_due_date() {
      return DateFormatter(self.due_date, 'MD');
    },
    get formated_due_date_input() {
      const date = self.due_date ? new Date(self.due_date) : new Date();
      const year = date.getFullYear().toString().padStart(4, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    get character_limit() {
      return self.parent.character_limit;
    }
  }))
  .actions(self => ({
    savePin: debounce(() => self.updatePin(self), 700),
    updatePin: flow(function* () {
      try {
        yield request.put(self.baseURL, getSnapshot(self));
        message({ type: 'success', text: 'Successfully updated pin.' });
      } catch (err) {
        throw err;
      } finally {
      }
    }),
    deletePin: flow(function* (e) {
      e.stopPropagation();
      const confirmDelete = yield confirm({
        text: 'Are you sure you want to delete this pin? You cannot undo these changes',
        okText: 'Delete'
      });
      if (!confirmDelete) return;
      try {
        yield request.delete(self.baseURL);
        message({ type: 'success', text: 'Successfully deleted pin.' });
        self.parent.removePin(self);
      } catch (err) {
        throw err;
      } finally {
      }
    }),
    addCampaignContentLink: flow(function* ({ content_link_id, url }) {
      try {
        const { data } = yield request.post(`${self.baseURL}/content-link`, {
          content_link_id,
          url
        });
        self.campaign_content_links.push(data);
        message({
          type: 'success',
          text: 'Successfully added content link url.'
        });
      } catch (err) {
        throw err;
      } finally {
      }
    }),
    // Setters
    setIsConfigured(is_configured) {
      self.is_configured = is_configured;
      self.updatePin();
    },
    setPinterestContentType(pinterest_content_type) {
      self.pinterest_content_type = pinterest_content_type;
      self.savePin();
    },
    setDescription(description) {
      if (description.length > self.character_limit?.description) {
        message({
          type: 'error',
          text: 'You have reached the maxium character limit for this field.'
        });
        return;
      }
      self.description = description;
      self.savePin();
    },
    setDueDate(due_date) {
      const [year, month, day] = due_date.split('-').map(Number);
      const formattedDate = new Date(year, month - 1, day);
      self.due_date = formattedDate.toISOString();
      self.savePin();
    },
    setTitle(title) {
      if (title.length > self.character_limit?.title) {
        message({
          type: 'error',
          text: 'You have reached the maxium character limit for this field.'
        });
        return;
      }
      self.title = title;
      self.savePin();
    }
  }));

export const CampaignPinterestModel = model('CampaignPinterestModel', {
  id: identifier,
  status: maybeNull(StatusModel),
  keyword_research: maybeNull(KeywordResearchModel),
  pins: array(PinterestPinModel),
  selected_pin: safeReference(PinterestPinModel),
  recycle_at: maybeNull(string)
})
  .views(self => ({
    get parent() {
      return getParent(self, 1);
    },
    get campaign() {
      return self.parent;
    },
    get baseURL() {
      return `${self.parent.baseURL}/campaign-pinterest/${self.id}`;
    },
    get sorted_due_dates() {
      const due_dates = [...self.pins].sort(
        (a, b) => new Date(a.due_date) - new Date(b.due_date)
      );
      return due_dates;
    },
    get due_date() {
      return self.sorted_due_dates[0]?.due_date;
    },
    get formated_due_date() {
      return DateFormatter(self.due_date, 'MD');
    },
    get character_limit() {
      return rootStore.WorkspaceStore.current_workspace.getCharacterLimit(
        'PINTEREST'
      );
    },
    get percentage_complete() {
      const allStatusus =
        rootStore.WorkspaceStore.current_workspace.statusOptions(['PINTEREST']);
      const statusIndex = allStatusus.findIndex(
        status => status?.id === self.status?.id
      );

      const totalStatusCount = allStatusus.length - 1;
      if (statusIndex === -1) return 0;
      return Math.round((statusIndex / totalStatusCount) * 100);
    }
  }))
  .actions(self => ({
    updateDueDates: flow(function* (due_date) {
      const confirmUpdate = yield confirm({
        text: 'Are you sure you want to update the base due date?',
        children:
          'If you continue, all current Pinterest pin due dates will be changed as well.',
        okText: 'Continue'
      });
      if (!confirmUpdate) return;

      const { data } = yield request.put(`${self.baseURL}/dates`, { due_date });
      message({ type: 'success', text: 'Successfully updated dates.' });
      self.pins = data;
    }),
    setStatus: flow(function* (status_id) {
      try {
        const { data } = yield request.put(`${self.baseURL}/status`, {
          status_id
        });
        message({ type: 'success', text: 'Successfully changed status.' });
        self.status = data;
      } catch (err) {
        throw err;
      } finally {
      }
    }),
    addPin: flow(function* () {
      try {
        const { data } = yield request.post(self.baseURL);
        message({ type: 'success', text: 'Successfully added pin.' });
        self.pins.push(data);
      } catch (err) {
        throw err;
      } finally {
      }
    }),
    removePin(pin) {
      destroy(pin);
    },
    setSelectedPin(id) {
      self.selected_pin = id;
    }
  }));

const PlatformsModel = model('PlatformsModel', {
  campaign_platforms: array(
    union({
      dispatcher: snapshot =>
        compose(
          CampaignPlatformModel,
          model('CampaignPlatformDataModel', {
            data: CampaignPlatformDataMap.get(snapshot.platform)
          })
		  .actions(self => ({
            saveData: debounce(() => self.updateData(), 700),
            updateData: flow(function* () {
              try {
                yield request.put(self.baseURL, getSnapshot(self));
                message({
                  type: 'success',
                  text: 'Successfully updated campaign platform.'
                });
              } catch (err) {
                throw err;
              } finally {
              }
            })
          }))
        )
    })
  )
});

const BaseCampaignModel = model('CampaignModel', {
  id: identifier,
  url_slug: maybeNull(string),
  idea: IdeaModel,
  campaign_social: maybeNull(CampaignSocialModel),
  campaign_pinterest: maybeNull(CampaignPinterestModel),
  _url_slug: maybeNull(string)
})
  .views(self => ({
    get parent() {
      return getParent(self, 1);
    },
    get baseURL() {
      return `${rootStore.WorkspaceStore.current_workspace.baseURL}/campaigns/${self.id}`;
    },
    get permalink() {
      return `${rootStore.WorkspaceStore.current_workspace.domain || ''}${
        self.url_slug || ''
      }`;
    },
    get title() {
      return self.idea.title;
    },
    get sorted_due_dates() {
      const due_dates = [...self.campaign_platforms];
      if (self.campaign_social && self.campaign_social.social_posts.legnth > 0) {
        due_dates.push(self.campaign_social);
      }
      if (self.campaign_pinterest) {
        due_dates.push(self.campaign_pinterest);
      }

      return due_dates
        .map(platform => platform?.due_date)
        .sort((a, b) => new Date(a) - new Date(b));
    },
    get due_date() {
      return self.sorted_due_dates[0];
    },
    get formated_due_date() {
      return DateFormatter(self.sorted_due_dates[0], 'MD');
    },
    get date_range() {
      const first = self.sorted_due_dates.at(0);
      const last = self.sorted_due_dates.at(self.sorted_due_dates.length - 1);
      return `${DateFormatter(first, 'MD')} - ${DateFormatter(last, 'MD')}`;
    },
    get status() {
      const statuses = [
        ...self.campaign_platforms,
        self.campaign_social,
        self.campaign_pinterest
      ].map(platform => platform?.status);

      let filtered = statuses.filter(status => status?.type === 'ACTIVE');
      if (filtered.length === 0) {
        filtered = statuses.filter(status => status?.type === 'DONE');
      }
      if (filtered.length === 0) {
        filtered = statuses.filter(status => status?.type === 'CLOSED');
      }
      const sorted = filtered.sort((a, b) => a.order - b.order);
      return sorted.at(0) || { name: 'No Specified Status', color: '#FFFFFF' };
    },
    get recycle_at() {
      if (self.campaign_pinterest?.recycle_at) return true;
      if (self.campaign_social?.recycle_at) return true;
      if (
        self.campaign_platforms.find(
          campaign_platform => campaign_platform?.recycle_at
        )
      )
        return true;
      return false;
    },
    get sponsored_content() {
      if (
        self.campaign_platforms.find(
          campaign_platform => campaign_platform?.sponsored_content
        )
      )
        return true;
      return false;
    },
  }))
  .actions(self => ({
    // Setters
    afterCreate() {
      self._url_slug = self.url_slug;
    },
    saveCampaign: debounce((...props) => self.updateCampaign(...props), 700),
    updateCampaign: flow(function* (showConfirm = true) {
      try {
        if (showConfirm) {
          const confirmUpdate = yield confirm({
            text: 'Are you sure you want to update your URL Slug?',
            okText: 'Update'
          });
          if (!confirmUpdate) {
            self.url_slug = self._url_slug;
            return;
          }
        }

        yield request.put(self.baseURL, getSnapshot(self));
        message({ type: 'success', text: 'Successfully updated campaign.' });
        self._url_slug = self.url_slug;
      } catch (err) {
        throw err;
      } finally {
      }
    }),
    deleteCampaign: flow(function* (onComplete) {
      const url = `${self.baseURL}`;
      try {
        const confirmDelete = yield confirm({
          text: 'Are you sure you want to delete this campaign? You cannot undo these changes',
          okText: 'Delete'
        });
        if (!confirmDelete) return;

        yield request.delete(url);
        onComplete();
        // self.props.navigate('/campaigns', { replace: true });
        message({ type: 'success', text: 'Successfully deleted campaign.' });
        destroy(self);
      } catch (err) {
        throw err;
      } finally {
      }
    }),
    setUrlSlug(url_slug, showConfirm) {
      if (url_slug.at(0) !== '/') {
        url_slug = `/${url_slug}`;
      }
      self.url_slug = url_slug.replace(' ', '-');
      self.saveCampaign(showConfirm);
    },
    setDueDate: flow(function* (due_date) {
      const confirmUpdate = yield confirm({
        text: 'Are you sure you want to update your pillar campaign due date?',
        okText: 'Update'
      });
      if (!confirmUpdate) {
        return;
      }

      const { data } = yield request.post(`${self.baseURL}/regenerate`, {
        start_date: startOfDay(new Date(due_date)).toISOString()
      });

      for (const platform of self.campaign_platforms) {
        const datum = data.campaign_platforms.find(cp => cp.id === platform.id);
        platform.due_date = datum.due_date;
      }

	  if(self.campaign_pinterest) {
		for (const pin of self.campaign_pinterest.pins) {
			const datum = data.campaign_pinterest.pins.find(p => p.id === pin.id);
			pin.due_date = datum.due_date;
		}
	  }

      for (const social_post of self.campaign_social.social_posts) {
        const datum = data.campaign_social.social_posts.find(
          sp => sp.id === social_post.id
        );
        social_post.due_date = datum.due_date;
      }

      message({
        type: 'success',
        text: 'Successfully updated campaign due dates.'
      });
    })
  }));

export const CampaignModel = compose(
  'CampaignModel',
  BaseCampaignModel,
  PlatformsModel
);
