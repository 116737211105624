import { getSnapshot, types } from 'mobx-state-tree';
import { PlatformEnum } from 'models/global/global.model';
import { UserModel } from 'models/user.model';
import { WorkspaceModel } from 'models/workspace.model';
import { rootStore } from 'stores';
import CampaignsModel from 'views/app/campaigns/campaigns.model';
import { PlatformOrderList } from 'utils/utils';

const { array, model, maybeNull } = types;

const FilterModel = model('FilterModel', {
	platforms: array(PlatformEnum),
	_workspace: maybeNull(WorkspaceModel),
	campaigns: maybeNull(CampaignsModel),
	users: array(UserModel),
	sponsoredContent: false,
	recycledContent: false,
	filterType: ''
})
	.views((self) => ({
		// get baseURL() {
		// 	return `/organizations/${self.organization_id}/workspaces/${self.id}`;
		// },
		get usersBaseURL() {
			return `/organizations/${self.organizationId}/users`;
		},
		get organizationId() {
			return self.props.params.organization_id || self.AuthStore.currentOrganizationId;
		},
		get workspace() {
			return self.WorkspaceStore.current_workspace;
		},
		get sortedPlatforms() {
			const platforms = [...rootStore.WorkspaceStore.current_workspace.platforms].sort((a, b) => {
				const indexA = PlatformOrderList.findIndex(type => a === type);
				const indexB = PlatformOrderList.findIndex(type => b === type);
				return indexA - indexB;
			});
				  
		  if (rootStore.WorkspaceStore.current_workspace.primary_platform) {
				const index = platforms.findIndex(p => p === rootStore.WorkspaceStore.current_workspace.primary_platform);
				platforms.unshift(platforms.splice(index, 1)[0]);
			}

      return platforms;
		},
		hasPlatform(value) {
			return self.platforms.includes(value);
		},
		hasUser(id) {
			return self.users.find((user) => user.id === id);
		},
	}))
	.actions((self) => ({
		setSponsoredContent(value) {
			self.sponsoredContent = value;
		},
		setRecycledContent(value) {
			self.recycledContent = value;
		},
		// Platform
		addPlatform(value) {
			self.platforms.push(value);
		},
		removePlatform(value) {
			const index = self.platforms.findIndex((platform) => platform === value);
			self.platforms.splice(index, 1);
		},
		togglePlatform(value) {
			if (self.hasPlatform(value)) {
				self.removePlatform(value);
			} else {
				self.addPlatform(value);
			}
		},
		clearFilters(type) {
			self.platforms.clear();
			self.users.clear();
			self.sponsoredContent = false;
			self.recycledContent = false;
			localStorage.removeItem(`${type}-FILTER-DATA`);
			self.persistFiltersOnWorkspaceConfig({}, type);
		},
		applyFilters(type) {
			const filters = {
				users: self.users,
				platforms: self.platforms,
				sponsoredContent: self.sponsoredContent,
				recycledContent: self.recycledContent
			}
			
			self.persistFiltersOnWorkspaceConfig(filters, type);
			self.persistFilters(filters, type);
		},
		persistFiltersOnWorkspaceConfig(filters, type){
			switch (type) {
				case 'CAMPAIGNS':
					rootStore.WorkspaceStore.setCampaignsFilters({ ...filters, users: filters.users ? filters.users.map( user => user.id ) : []});
					break;
				case 'PLANNER':
					rootStore.WorkspaceStore.setPlannerFilters(filters);
					break;
			
				default:
					break;
			}
		},
		setFilterType(type){
			if(type){
				self.filterType = type;
				self.getStoredDataByType(type)
			}
		},

		// Users
		addUser(id, user) {
			self.users.push({...user, active: true });
		},
		removeUser(id) {
			const index = self.users.findIndex((user) => user.id === id);
			self.users.splice(index, 1);
		},
		toggleUser(id, user) {
			if (self.hasUser(id)) {
				self.removeUser(id);
			} else {
				self.addUser(id, getSnapshot(user));
			}
		},
		fillStoredFilters(storedData){
			const data = JSON.parse(storedData);
			if(data?.users){
				data.users && self.users.replace(data.users);
			}
			if(data?.platforms){
				data.platforms && self.platforms.replace(data.platforms);
			}
			self.sponsoredContent = !!data.sponsoredContent;
			self.recycledContent = !!data.recycledContent;
		},
		getStoredDataByType(type){
			const storedData = localStorage.getItem(`${type}-FILTER-DATA`);
			if(storedData){
				self.fillStoredFilters(storedData);
			}
		},
		persistFilters(filters, type) {
			localStorage.setItem(`${type}-FILTER-DATA`, JSON.stringify(filters));	
		}
	}));

export default FilterModel;