import request from 'api/request';
import { destroy, flow, types } from 'mobx-state-tree';
import { ContentLinkModel, ContentLinkTypeEnum } from 'models/content-link.model';

import { Modal } from 'components';
const { message } = Modal;

const { boolean, array, model, optional, maybeNull, string } = types;

const ContentLinksModel = model('ContentLinksModel', {
	content_links: array(ContentLinkModel),
	isLoading: optional(boolean, true),

	// Temporary
	content_link_name: optional(string, ''),
	content_link_type: maybeNull(ContentLinkTypeEnum),
})
	.views((self) => ({
		get baseURL() {
			return `${self.workspace.baseURL}/settings/content-links`;
		},
		get workspace() {
			return self.props.workspace;
		},
	}))
	.actions((self) => ({
		afterCreate() {
			self.fetchContentLinks();
		},
		fetchContentLinks: flow(function* () {
			try {
				const { data } = yield request.get(self.baseURL);
				self.content_links = data;
			} catch (err) {
				console.error(err);
				throw err;
			} finally {
				self.isLoading = false;
			}
		}),
		addContentLink: flow(function* (platform) {
			try {
				const { data } = yield request.post(self.baseURL, {
					platform,
					name: self.content_link_name,
					type: self.content_link_type,
				});

				self.setContentLinkName('');
				self.setContentLinkType(null);

				self.content_links.push(data);
				message({ type: 'success', text: 'Successfully added content link.' });
			} catch (err) {
				console.error(err);
				throw err;
			}
		}),
		removeContentLink(content_link) {
			destroy(content_link);
		},
		setContentLinkName(name) {
			self.content_link_name = name;
		},
		setContentLinkType(type) {
			self.content_link_type = type;
		},
	}));

export default ContentLinksModel;
