
import React, { Component } from 'react';
import { Observer } from 'mobx-react-lite';
import { Button, Input, Table } from 'components';
import classNames from 'classnames';

export default class KeywordResearch extends Component {

	render() {
		const { additional_keywords, keyword_research, platform, title } = this.props;

		const baseFooter = <>
			<p className="mb-1">
				<strong>Questions</strong>
			</p>
			<Observer>{() => (
				<div className={classNames('tejik-keyword-questions')}>
					{keyword_research.keyword_questions.map((keyword_question) => (
						<Input
							key={keyword_question.id}
							placeholder="question people ask about topic"
							value={keyword_question.text || ''}
							onChange={(e) => keyword_question.setText(e.target.value)}
						/>
					))}
				</div>
			)}</Observer>
			<div className={classNames('tejik-keyword-research-table-footer')}>
				<Button icon="add" type="hollow" onClick={() => keyword_research.addKeywordQuestion()} />
			</div>
		</>

		const footer = {
			'YOUTUBE': <>
				<p className="mb-1">
					<strong>YouTube Hashtags</strong>
				</p>
				<Observer>{() => (
					<div className={classNames('tejik-keyword-hashtags')}>
						{keyword_research.keyword_hashtags.map((keyword_hashtag) =>
							<Input
								key={keyword_hashtag.id}
								placeholder="# for video"
								value={keyword_hashtag.tag || ''}
								onChange={(e) => keyword_hashtag.setTag(e.target.value)}
							/>
						)}
					</div>
				)}</Observer>
				<div className={classNames('tejik-keyword-research-table-footer')}>
					<Button icon="add" type="hollow" onClick={keyword_research.addKeywordHashtag} />
				</div>
			</>,
			'PINTEREST': additional_keywords ? <Table
				columns={[{
					title: 'Search Keyword Research (SEO)',
					render: (keyword) => (
						<div>{keyword?.text}</div>
					),
				}, {
					title: 'volume',
					render: (keyword) => (
						<div>{keyword?.volume}</div>
					),
					width: 'fit',
				}]}
				data={additional_keywords}
			/> : <></>,
		}[platform] || baseFooter;

		return (
			<Observer>
				{() => (<>
					<Table
						columns={[{
							title: title,
							render: (keyword) => (
								<Input
									hidden
									placeholder="long tail keyword phrases"
									value={keyword?.text || ''}
									size="small"
									style={{ width: '100%' }}
									onChange={(e) => keyword.setText(e.target.value)} />
							),
						}, {
							title: 'volume',
							render: (keyword) => (
								<Input
									hidden
									placeholder="#"
									value={keyword?.volume || ''}
									size="small"
									onChange={(e) => keyword.setVolume(e.target.value)}
								/>
							),
							headerStyle: () => ({ width: '100px' }),
							rowStyle: () => ({ width: '100px' }),
						}]}
						data={keyword_research.keywords}
					/>
					<div className={classNames('tejik-keyword-research-table-footer')}>
						<Button icon="add" type="hollow" onClick={() => keyword_research.addKeyword()} />
					</div>

					{footer}
				</>
				)}
			</Observer>
		);
	}
}