import React, { Component } from 'react';
import ReactDatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

import classNames from 'classnames';
import { Observer } from 'mobx-react-lite';

export default class DatePicker extends Component {
	render() {
		return (<Observer>{() => (
			<ReactDatePicker wrapperClassName={classNames('tejik-date-picker-wrapper')} {...this.props} />
		)}</Observer>
		);
	}
}