import { observer } from 'mobx-react-lite';
import { ModelConnector } from 'stores';
import { Button, Form, Input, Title } from 'components';
import { TejikLogo } from 'components/svg/svg';
import ForgotPasswordModel from './forgot-password.model';

import classNames from 'classnames/bind';
import styles from './forgot-password.scss';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);
const ForgotPassword = observer(({ model }) => {
	return (
		<div className={cx('tejik-password-container')}>
			<div className={cx('tejik-password')}>
				<TejikLogo />
				{!model.successfulRequest ? <>
					<Title size={4} weight={600}>Forgot your password?</Title>
					<Form onSubmit={model.resetPassword} style={{ paddingRight: 0, paddingLeft: 0 }}>
						<Form.Item>
							<Input
								value={model.email}
								onChange={(e) => model.setEmail(e.target.value)}
								type="text"
								placeholder="email"
								autoComplete="email"
							/>
						</Form.Item>
						<Form.Item>
							<Button htmlType="submit" type="secondary" size="large" style={{ width: '100%', padding: 14, justifyContent: 'center', flex: 1 }}>Continue</Button>
						</Form.Item>
					</Form>
				</>
					:
					<p>
						Successfully requested a new password change. Please check your email to reset your password.
					</p>
				}
			</div>
		</div>
	);
});

export default ModelConnector(ForgotPassword, ForgotPasswordModel);
