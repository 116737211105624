import { useState, useEffect } from 'react';
import {Button, Container, Input, Switch} from 'components';
import {ModelConnector, rootStore} from 'stores';
import {observer} from 'mobx-react-lite';
import PlannerModel from './planner.model';
import Calendar from './calendar/calendar.view';
import CreateIdeaModal from 'modals/createIdea/createIdea';
import FiltersModal from 'modals/filters/filters';
import request from "../../../api/request";
import classNames from 'classnames/bind';
import SVG from 'components/svg/svg';
import styles from './planner.scss';
import IdeasDrawer from "./ideas-drawer/ideas-drawer.view";
import ConfirmationModal from "../../../modals/confirmationModal";
import EventLink from "./calendar/event-link.view";
import {useDndMonitor} from "@dnd-kit/core";
import {DateTime} from "luxon";

const cx = classNames.bind(styles);

const MONTHS_OF_YEAR = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

const Planner = observer(({model}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
	const [weeksToShow, setWeeksToShow] = useState(6);
	const [selectedDate, setSelectedDate] = useState((new Date()).toDateString());
  const [showIdeasDrawer, setShowIdeasDrawer] = useState(false);
  const [ideaMovedToPlanner, setIdeaMovedToPlanner] = useState(undefined);
  const [ideaToRemove, setIdeaToRemove] = useState(undefined);

  useDndMonitor({
    onDragEnd(event) {
      const {active, over} = event;
      if (!active || !over) {
        return;
      }
      if (!over.data.current.supports.includes(active?.data?.current.type)) {
        return;
      }
      const {data: {current: {date, index}}} = over
      if (active.data.current.type === 'idea-item') {
        const today = DateTime.now();
        const dateConverted = DateTime.fromJSDate(date)
        const diff = dateConverted.diff(today, ['days']).toObject()
        if (diff?.days >= 0) {
          setIdeaMovedToPlanner({idea: active.data.current.idea, date, index});
          model.toggleConfirmationToMoveAnIdeaModal();
        }
        else {
          model.toggleInvalidDateIdeaModal();
        }
      }

    },
  });
  
	const setSelectedDateEvents = (date) => {
		setSelectedDate(date.toDateString());
		model.setFooterEvents(date);
	};

  const setToPreviousMonth = () => {
    const nextMonthIndex = currentDate.getMonth();
    if (nextMonthIndex <= 0) {
      const date = new Date(
        currentDate.getFullYear() - 1,
        11,
        currentDate.getDate()
      );
      setCurrentDate(date);
    } else {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        currentDate.getDate()
      );
      setCurrentDate(date);
    }
  };

  const setToNextMonth = () => {
    const nextMonthIndex = currentDate.getMonth() + 2;
    if (nextMonthIndex > 12) {
      const date = new Date(
        currentDate.getFullYear() + 1,
        0,
        currentDate.getDate()
      );
      setCurrentDate(date);
    } else {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        currentDate.getDate()
      );
      setCurrentDate(date);
    }
  };

	useEffect(() => {
		const year = currentDate.getFullYear();
		const month = currentDate.getMonth();
	
		const firstDay = new Date(year, month, 1);
		const lastDay = new Date(year, month + 1, 0);
	
		const firstDayOfWeek = firstDay.getDay();
		const totalDays = lastDay.getDate();
	
		const daysInFirstWeek = 7 - firstDayOfWeek;
		const remainingDays = totalDays - daysInFirstWeek;
	
		const fullWeeks = Math.ceil(remainingDays / 7);
		setWeeksToShow(1 + fullWeeks);
	}, [currentDate]);

  const handleConfirmMovingTheIdea = () => {
    const url = `${rootStore.WorkspaceStore.current_workspace.baseURL}/campaigns`;
    const {idea, date} = ideaMovedToPlanner;
    request.post(url, {idea_id: idea.id, user_defined_due_date: date}).then(response => {
      setIdeaToRemove(idea);
      model.fetchCampaigns();
    }).finally(
      () => {
        model.toggleConfirmationToMoveAnIdeaModal();
        setIdeaMovedToPlanner(undefined);
      }
    )
  }

  return (
    <Container noPadding fillHeight className={cx('planner-container')}>
      <div className={cx('calendar-actions')}>
        <div className={cx('calendar-filters')}>
          <Button
            size="small"
            iconSize={16}
            type="secondary"
            shape="hollow-gray"
            onClick={() => { setCurrentDate(new Date()); setSelectedDate((new Date()).toDateString()); model.setFooterEvents(new Date()); }}
			      style={{ padding: 12 }}
          >
            Today
          </Button>
          <Switch className={cx('show-desktop-only')} label={'Me'} icon={'person'} onChange={model.toggleMeFilter} value={model.toggleMeFilterApplied}/>
        </div>
        <div className={cx('show-mobile-only')} style={{ marginLeft: 32 }}>
          <Switch label={'Me'} icon={'person'} onChange={model.toggleMeFilter} value={model.toggleMeFilterApplied}/>
        </div>
        <div className={cx('calendar-month', 'show-desktop-only')}>
          <Button
            type="text"
            icon="chevron_left"
            onClick={setToPreviousMonth}
          />
          <div className={cx('calendar-month-name')}>
            {MONTHS_OF_YEAR[currentDate.getMonth()]} {currentDate.getFullYear()}
          </div>
          <Button type="text" icon="chevron_right" onClick={setToNextMonth}/>
        </div>
        <div className={cx('calendar-ideas', 'show-desktop-only')}>
          <Input
            size="small"
            icon="search"
            hasFilter={true}
            onFilterClick={model.filterModalClick}
            value={model.searchTerm}
            onChange={e => model.setSearchTerm(e.target.value)}
            style={{ width: 300, paddingLeft: '68px', marginRight: '1em'}}
			      inputStyle={{ backgroundColor: 'transparent', height: '100%' }}
            filterCount={model.filterCount}
          />
          <Button
            size="small"
            iconSize={16}
            type="secondary"
            shape="hollow-gray"
            icon="emoji_objects"
						freezeStyle={showIdeasDrawer}
            onClick={() => setShowIdeasDrawer(!showIdeasDrawer)}
						style={{ padding: 12 }}
          >
            Ideas <SVG className="tejik-card-action" name="chevron_right" size={20} />
          </Button>
        </div>
        <div className={cx('calendar-ideas', 'show-mobile-only')}>
          <Input
            size="small"
            icon="search"
            hasFilter={true}
            onFilterClick={model.filterModalClick}
            onIconClick={model.toggleMobileSearch}
            value={model.searchTerm}
            onChange={e => model.setSearchTerm(e.target.value)}
            style={{ width: 155, paddingLeft: '50px'}}
			      inputStyle={{ backgroundColor: 'transparent', padding: 8 }}
            iconStyle={{ paddingRight: 6 }}
            filterCount={model.filterCount}
          />
        </div>
      </div>
      <div>
        {model.visibleSearchMobile && 
          <Input
            icon="search"
            value={model.searchTerm}
            onChange={e => model.setSearchTerm(e.target.value)}
            style={{ width: '100%', padding: '1em', marginTop: -16 }}
            inputStyle={{ backgroundColor: 'transparent', padding: 8, height: '100%' }}
          />
        }
        <div className={cx('calendar-month-mobile')}>
          <Button
            type="text"
            icon="chevron_left"
            onClick={setToPreviousMonth}
          />
          <div className={cx('calendar-month-name')}>
            {MONTHS_OF_YEAR[currentDate.getMonth()]} {currentDate.getFullYear()}
          </div>
          <Button type="text" icon="chevron_right" onClick={setToNextMonth}/>
        </div>
        <Container
          isLoading={model.isLoading}
          noPadding
          fillHeight
          className={cx({
            'planner-calendar-container-loading': model.isLoading
          })}
        >
          <div style={{display: 'flex', flexDirection: 'row'}} className='planner-calendar-container'>
            <Calendar 
							date={currentDate} 
							events={model.events} 
							weeksToShow={weeksToShow} 
							clickHandler={setSelectedDateEvents} 
							selectedDate={selectedDate}
							showRightBorder={!showIdeasDrawer}
						/>
            {
              showIdeasDrawer && <IdeasDrawer onClose={() => setShowIdeasDrawer(!showIdeasDrawer)} ideaToRemove={ideaToRemove}/>
            }
          </div>
          <div className={cx('show-mobile-only', 'today-tasks-view')} >
            <div className={cx('day-name')}>
              {model.selectedDate.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}
            </div>
            {model.selectedDateEvents.length > 0 ?
              <div style={{ marginLeft: '1em', marginRight: '1em' }}>
                {model.selectedDateEvents.map((event) => <EventLink key={event.id} event={event} footer={true}/>)}
              </div>
              :
              <div className={cx('day-name')} style={{ fontWeight: 500 }}>{model.selectedDate.toDateString() === (new Date()).toDateString() ? 'No tasks due for today' : 'No tasks due for this date'}</div>
            }  
          </div>
        </Container>
      </div>
      {
        model.showConfirmationToMoveAnIdeaModal && (
          <ConfirmationModal
            onConfirm={handleConfirmMovingTheIdea}
            onCancel={() => {
              model.toggleConfirmationToMoveAnIdeaModal()
            }}
            confirmButtonText={'Move'}
            title={'Move idea'}
            description={'Are you sure you want to move this idea to a campaign?'}
          />
        )
      }
      {
        model.showInvalidDateIdeaModal && (
          <ConfirmationModal
            onClose={() => model.toggleInvalidDateIdeaModal()}
            onConfirm={() => model.toggleInvalidDateIdeaModal()}
            showCancel={false}
            confirmButtonText={'Ok'}
            description={'Cannot drag idea to a past date'}
          />
        )
      }
      {model.visibleCreateIdeaModal && (
        <CreateIdeaModal
          onOk={model.onIdeaCreation}
          onCancel={model.hideCreateIdeaModal}
          visible={model.visibleCreateIdeaModal}
        />
      )}
      {model.visibleFilterModal && (
        <FiltersModal
          onOk={model.filterModalClick}
          onCancel={model.filterModalClick}
          visible={model.visibleFilterModal}
          filterType={'PLANNER'}
        />
      )}
    </Container>
  );
});

export default ModelConnector(Planner, PlannerModel);
