import { observer } from 'mobx-react-lite';
import { ModelConnector } from 'stores';
import {Button, Container, Info, Input, List, Switch, Toggle} from 'components';
import CreateIdeaModal from 'modals/createIdea/createIdea';

import CampaignsModel from './campaigns.model';

import classNames from 'classnames/bind';
import styles from './campaigns.scss';
import { Link } from 'react-router-dom';
import FiltersModal from 'modals/filters/filters';
const cx = classNames.bind(styles);

const Campaigns = observer(({ model }) => {
  return (
    <Container
      className='campaigns-container'
      title="Campaigns"
      isLoading={model.isLoading}
      actions={[
        <Switch label={'Me'} icon={'person'} onChange={model.toggleMeFilter} value={model.toggleMeFilterApplied}/>,
        <Input
          key="search-bar"
          icon="search"
          size="small"
          hasFilter={true}
          value={model.searchTerm}
          onFilterClick={model.filterModalClick}
          onChange={e => model.setSearchTerm(e.target.value)}
          style={{ width: 400, paddingLeft: '68px' }}
		  filterCount={model.filterCount}
        />,
        <Button
          key="add-button"
          type="secondary"
          shape="hollow"
          icon="add"
          onClick={model.showCreateIdeaModal}
        />,
        <Link to="/ideas" key="idea-button">
          <Button type="secondary" shape="hollow" icon="emoji_objects" />
        </Link>,
        <Link to="/planner" key="calendar-button">
          <Button type="secondary" shape="hollow" icon="calendar_today" />
        </Link>,
        <Toggle
          key="toggle_buttons"
          options={[
            { label: 'Active', value: 'active' },
            { label: 'Closed', value: 'closed' }
          ]}
          onToggle={model.setCampaignListState}
        />
      ]}
    >
      <List
        key={model.campaignListState}
        rows={model.campaignList}
        className={cx('campaigns-list')}
      />
      {model.visibleCreateIdeaModal && (
        <CreateIdeaModal
          onOk={model.hideCreateIdeaModal}
          onCancel={model.hideCreateIdeaModal}
          visible={model.visibleCreateIdeaModal}
        />
      )}
      {model.visibleFilterModal && (
        <FiltersModal
          onOk={model.filterModalClick}
          onCancel={model.filterModalClick}
          visible={model.visibleFilterModal}
		  filterType={'CAMPAIGNS'}
        />
      )}
      <Info infoKey="campaigns" />
    </Container>
  );
});

export default ModelConnector(Campaigns, CampaignsModel);
