import React, { Component } from 'react';

import classNames from 'classnames';
import SVG from 'components/svg/svg';
import { Modal } from 'components';

export default class Input extends Component {
	onChange = (e) => {
		if(this.props.type === 'number') {
			const minVal = this.props.min !== null ? parseInt(this.props.min) : 1;
			if(parseInt(e.target.value) < minVal) {
				e.target.value = minVal.toString();
			}
		}
		this.props.onChange(e);
	}

	onIconClick = (e) => {
		if(this.props.onIconClick) {
			this.props.onIconClick();
		} else if (this.props.canCopy) {
			navigator.clipboard.writeText(this.props.value);
			Modal.message({ type: 'success', text: 'Successfully copied content.' });
		}
	}

	preffix() {
		if (this.props.preffix) {
			return (
				<span className={classNames('preffix')}>
					{this.props.preffix}
				</span>
			);
		} else return <></>;
	}

	icon() {
		if (this.props.canCopy || this.props.icon) {
			return (
				<SVG
					style={this.props.iconStyle}
					className={classNames({'icon': !this.props.leftIcon}, {'left-icon': this.props.leftIcon}, { 'pointer': this.props.canCopy })}
					name={this.props.canCopy ? 'content_copy' : (this.props.icon ? this.props.icon : '')}
					onClick={this.onIconClick}
				/>
			);
		} else return <></>;
	}

	filterIcon() {
		if (this.props.hasFilter) {
			return (
				<div className={classNames({ 'filtered': this.props.filterCount > 0 })}>
					<div className={classNames('filter', { 'has-filters': this.props.filterCount > 0 })} onClick={this.props.onFilterClick}>
						<SVG className={classNames({'filter-icon': this.props.filterCount > 0 })}
							name='filter_list' size={24}
						/>
						<SVG className={classNames({'filter-icon': this.props.filterCount > 0 })}
							name='arrow_drop_down' size={24}
						/>
					</div>
					{this.props.filterCount > 0 &&
						<div className={'badge'}>
							{this.props.filterCount}
						</div>
					}
				</div>
			);
		} else return <></>;
	}

	input() {
		return (
			<input
				className={classNames({ 'small': this.props.size === 'small', 'hidden': this.props.hidden, 'has-filters': this.props.hasFilter, 'has-filters-applied': this.props.filterCount > 0 }, this.props.className)}
				disabled={this.props.disabled}
				type={this.props.type}
				value={this.props.defaultValue || this.props.value}
				onClick={this.props.onClick}
				onChange={this.onChange}
				placeholder={this.props.placeholder}
				autoComplete={this.props.autocomplete}
				min={this.props.type === 'number' ? (this.props.min ? this.props.min : '1') : null}
				style={this.props.inputStyle}
			/>
		);
	}

	render() {
		const { className, defaultValue, disabled, hidden, placeholder, size, type, onClick, value, style, leftIcon } = this.props;

		if (type === 'textarea') {
			return (
				<div className={classNames('tejik-input-icon-wrapper')}>
					<textarea
						className={classNames({ 'small': size === 'small', 'hidden': hidden }, className)}
						disabled={disabled}
						type={type}
						value={defaultValue || value}
						onClick={onClick}
						onChange={this.onChange}
						placeholder={placeholder}
						style={style}
					/>
					{this.icon()}
				</div>
			);
		}

		return (
			<div className={classNames('tejik-input-preffix')} style={style}>
				{this.preffix()}
				<div className={classNames('tejik-input-icon-wrapper')}>
					{leftIcon && this.icon()}
					{this.filterIcon()}
					{this.input()}
					{!leftIcon && this.icon()}
				</div>
			</div>
		)
	}
}