import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {
  Button,
  Container,
  Form,
  Input,
  KeywordResearch,
  Modal
} from 'components';

import CampaignTasks from '../campaign-tasks/campaign-tasks.view';
import ContentLinks from '../../contentLinks';

import classNames from 'classnames/bind';
import styles from './podcast.scss';

const cx = classNames.bind(styles);

const PodcastEditForm = observer(({campaign_platform, showLinks}) => (
  <div>
    <Form.Row>
      <Form.Item label="Episode #" className={cx('episode-form-item')}>
        <Input
          value={campaign_platform?.data?.episode_number || ''}
          onChange={e =>
            campaign_platform.data.setEpisodeNumber(e.target.value)
          }
          type="text"
        />
        <div className={cx('character-limit-text')}></div>
      </Form.Item>
      <Form.Item label="Podcast Title">
        <Input
          value={campaign_platform?.data?.title || ''}
          onChange={e => campaign_platform.data.setTitle(e.target.value)}
          type="text"
          canCopy
        />
        <div className={cx('character-limit-text')}>
          {campaign_platform.character_limit.title -
            (campaign_platform.data.title?.length ?? 0)}{' '}
          characters left
        </div>
      </Form.Item>
    </Form.Row>
    <Form.Item label="Podcast Description">
      <Input
        value={campaign_platform?.data?.description || ''}
        onChange={e => campaign_platform.data.setDescription(e.target.value)}
        type="textarea"
        canCopy
      />
      <div className={cx('character-limit-text')}>
        {campaign_platform.character_limit.description -
          (campaign_platform.data.description?.length ?? 0)}{' '}
        characters left
      </div>
    </Form.Item>
    {showLinks && (
      <Form.Item>
        <ContentLinks
          campaign_content_links={campaign_platform.campaign_content_links}
          platform={campaign_platform.platform}
          addCampaignContentLink={campaign_platform.addCampaignContentLink}
        />
      </Form.Item>
    )}
  </div>
));

const PodcastView = observer(({campaign_platform, permalink}) => {
  const [visible, setVisible] = useState(false);

  if (
    campaign_platform.keyword_research === null ||
    campaign_platform.keyword_research.complete
  ) {
    return (
      <Container noPadding>
        <Container.Column span={8}>
          <div className={cx('keyword-research-button')}>
            {/*
             * TODO: Check _blank functionality
             */}
            <a
              className={cx('permalink')}
              href={`https://${permalink}`}
              //   target="_blank"
            >
              {permalink}
            </a>
            <Button
              type="secondary"
              shape="hollow"
              size="small"
              onClick={() => setVisible(true)}
              icon="query_stats"
              iconSize={12}
            >
              Keyword Research
            </Button>
          </div>
          <PodcastEditForm campaign_platform={campaign_platform} showLinks/>
		  <div className={cx('trash-container')}>
				<Button
					type="text"
					icon="delete"
					iconSize={20}
					onClick={e => {
						e.stopPropagation();
						campaign_platform.deletePlatform(campaign_platform.id);
					}}
					style={{padding: 0}}
				></Button>
			</div>
          <Modal
            title="Keyword Research"
            titleSize={5}
            visible={visible}
            onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            footer={[]}
            closable
          >
            <KeywordResearch
              keyword_research={campaign_platform.keyword_research}
              title="Search Keyword Research (SEO)"
              platform={campaign_platform.platform}
            />
          </Modal>
        </Container.Column>
        <Container.Column span={4} className={cx('campaign-tasks-container')}>
          <CampaignTasks
            campaign_tasks={campaign_platform?.sorted_campaign_tasks}
          />
        </Container.Column>
      </Container>
    );
  } else {
    return (
      <Container noPadding>
        <Container.Column span={6}>
          <KeywordResearch
            keyword_research={campaign_platform.keyword_research}
            title="Search Keyword Research (SEO)"
            platform={campaign_platform.platform}
          />
        </Container.Column>
        <Container.Column span={6}>
          <PodcastEditForm campaign_platform={campaign_platform}/>
          <div className={cx('button-trash-container')}>
            <Button
              onClick={campaign_platform.keyword_research.markKeywordResearchDone}
            >
              Keyword Research Done
            </Button>
            <Button
              type="text"
              icon="delete"
              iconSize={20}
              onClick={e => {
                e.stopPropagation();
                campaign_platform.deletePlatform(campaign_platform.id);
              }}
              style={{padding: 0}}
            ></Button>
          </div>

        </Container.Column>
      </Container>
    );
  }
});

export default PodcastView;
