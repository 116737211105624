import React, { Component } from 'react';
import classNames from 'classnames';

import { Menu, Popover } from 'components';
import SVG from 'components/svg/svg';

export default class Select extends Component {
	constructor(props) {
		super(props);
		const initialValue = props.defaultValue || props.value;

		this.state = { selectedValue: props.type === 'multi-select' && !Array.isArray(initialValue) ? [] : initialValue };
		this.selectElem = React.createRef();
	}

	componentDidUpdate(prevProps) {
		if(this.props.value !== prevProps.value) {
			this.setSelectedValue(this.props.value);
			this.forceUpdate();
		}
	}

	get menuItems() {
		return this.props.options.map((option) => ({
			action: option.disabled ? null : () => this.onSelect(option.value),
			header: option.header,
			icon: option.icon,
			text: option.label,
			value: option.value,
			description: option.description,
			disabled: option.disabled,
			hidden: option.hidden,
			state: this.props.type === 'multi-select' ? (this.state.selectedValue && this.state.selectedValue.includes(option.value) ? 'check' : 'add') : (this.props.type === 'checked-select' ? (this.state.selectedValue && this.state.selectedValue === option.value ? 'check' : null) : null),
			selected: this.props.type === 'multi-select' && this.state.selectedValue && this.state.selectedValue.includes(option.value),
		}))
	}

	get selectedItem() {
		if (this.props.type === 'multi-select' && Array.isArray(this.state.selectedValue)) {
			return this.state.selectedValue.map((singleElem) => this.menuItems.find((item) => item.value === singleElem));
		} else {
			return this.menuItems.find((item) => item.value === this.state.selectedValue);
		}
	}

	setSelectedValue = (selectedValue) => {
		this.setState({
			selectedValue,
		});
	}

	onSelect = (value) => {
		if (this.props.disabled) return;
		if (this.props.type === 'multi-select') {
			if (this.state.selectedValue.includes(value)) {
				// Remove
				const newState = this.state.selectedValue.filter((v) => v !== value);
				this.setSelectedValue(newState);
				this.props.onChange(newState);

			} else {
				// Add
				this.setSelectedValue([...this.state.selectedValue, value]);
				this.props.onChange([...this.state.selectedValue, value]);
			}
		} else {
			this.setSelectedValue(value);
			this.props.onChange(value);
		}

		if (this.props.type === 'multi-select') return;
		this.forceClose();
	}

	forceClose = () => {
		const event = new CustomEvent('force-close', {
			bubbles: true,
		});
		this.selectElem.current.dispatchEvent(event);
	}

	renderContent = ({ placeholder, label, style }) => {
		if (placeholder && !this.selectedItem) return <Menu.Item className={classNames('tejik-select-value', 'tejik-select-placeholder', 'text-ellipsis')} text={placeholder} />

		return (
			this.props.type === 'multi-select' ? (
				Array.isArray(this.selectedItem) && this.selectedItem.length > 0 ?
					<Menu.Item text={`${this.selectedItem.length} item(s)`} icon="" state="" description="" className="tejik-select-value" ellipsis style={style} />
					:
					placeholder && <Menu.Item text={placeholder} className={classNames('tejik-select-value', 'tejik-select-placeholder')} style={style} />
			)
			:
			(
				this.selectedItem ?
					<Menu.Item {...{...this.selectedItem, state: null}} description="" action={() => { }} className="tejik-select-value" ellipsis style={style} />
					:
					<Menu.Item text={label} className="tejik-select-value" ellipsis style={style}/>
			)
		)
	}

	render() {
		const { hidden, placeholder, label, size, style, inputStyle, className, menuClass, position } = this.props;

		return (
			<Popover position={position} content={<Menu items={this.menuItems} overflowMax={6} />} contentClass={classNames(menuClass)} style={style}>
				<div ref={this.selectElem} style={inputStyle} className={classNames('tejik-select', className, { 'tejik-select-small': size === 'small', 'hidden': hidden, 'tejik-multi-select': this.props.type === 'multi-select' })}>
					<div className={classNames('tejik-select-container')}>
						{this.renderContent({
							placeholder, label, inputStyle
						})}
					</div>

					<SVG name="expand_more" />
				</div>
			</Popover>
		);
	}
}